import { ProtocolInterface } from "../UI/Protocol/Protocol"

export interface Node {
  ip: string;
  name: string;
}

export const syscallFields = [
  'cgroupId',
  'containerId',
  'processId',
  'parentProcessId',
  'hostProcessId',
  'hostParentProcessId',
  'processName',
  'processPath',
]

export interface Resolution {
  ip: string;
  port: string;
  name: string;
  namespace: string;
  // eslint-disable-next-line
  pod?: any;
  // eslint-disable-next-line
  endpointSlice?: any;
  // eslint-disable-next-line
  service?: any;
  resolutionMechanism?: string
  cgroupId?: number;
  containerId?: string;
  processId?: number;
  processName?: string;
  parentProcessId?: number;
  hostProcessId?: number;
  hostParentProcessId?: number;
}

export interface KsError {
  type: string,
  msg: string;
}

export interface KsEvent {
  source: string;
  type: string;
  // eslint-disable-next-line
  data: any;
}

interface VLAN {
  id: number
  dot1q: boolean
}

export interface CaptureInterface {
  backend: string
  source: string
  vlan?: VLAN
}

export interface Entry {
  key: string;
  id: string;
  index?: number;
  stream: string;
  capture: CaptureInterface;
  node: Node;
  worker: string;
  proto: ProtocolInterface;
  tls: boolean;
  method?: string;
  methodQuery?: string;
  summary: string;
  summaryQuery: string;
  status?: number;
  statusQuery?: string;
  timestamp: Date;
  src: Resolution;
  dst: Resolution;
  outgoing: boolean;
  requestSize: number;
  responseSize: number;
  elapsedTime: number;
  passed: boolean;
  failed: boolean;
  record: string;
  event?: KsEvent;
  error?: KsError;
  duplicate: string;
  size: number;
}

export interface TableData {
  name: string;
  value: string | number;
  selector: string;
}

export interface SectionData {
  type: string;
  title: string;
  tableData: TableData[];
  encoding: string;
  mimeType: string;
  body: string;
  selector: string;
}

export interface Representation {
  request: SectionData[];
  response: SectionData[];
  event: SectionData[];
  data: SectionData[];
  syscallInfo: {
    src: SectionData[];
    dst: SectionData[];
  }
}
