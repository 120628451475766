import React from 'react'

interface IconProps {
  stroke?: string
}

export const NodeIcon: React.FC<IconProps> = ({ stroke }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 17.6656L7.03 20.0456C6.71894 20.2325 6.36289 20.3312 6 20.3312C5.63711 20.3312 5.28106 20.2325 4.97 20.0456L1.97 18.2456C1.67476 18.0682 1.43033 17.8176 1.26039 17.518C1.09045 17.2184 1.00075 16.88 1 16.5356V13.2956C1.00075 12.9512 1.09045 12.6128 1.26039 12.3132C1.43033 12.0136 1.67476 11.763 1.97 11.5856L6 9.16562M11 17.6656V12.1656M11 17.6656L14.97 20.0456C15.2811 20.2325 15.6371 20.3312 16 20.3312C16.3629 20.3312 16.7189 20.2325 17.03 20.0456L20.03 18.2456C20.3252 18.0682 20.5697 17.8176 20.7396 17.518C20.9096 17.2184 20.9992 16.88 21 16.5356V13.2956C20.9992 12.9512 20.9096 12.6128 20.7396 12.3132C20.5697 12.0136 20.3252 11.763 20.03 11.5856L16 9.16562M11 12.1656L6 9.16562M11 12.1656L6 15.1656M11 12.1656L16 9.16562M11 12.1656L16 15.1656M11 12.1656V6.66562M6 9.16562V4.79562C6.00075 4.45119 6.09045 4.11279 6.26039 3.81321C6.43033 3.51362 6.67476 3.263 6.97 3.08562L9.97 1.28562C10.2811 1.09873 10.6371 1 11 1C11.3629 1 11.7189 1.09873 12.03 1.28562L15.03 3.08562C15.3252 3.263 15.5697 3.51362 15.7396 3.81321C15.9096 4.11279 15.9992 4.45119 16 4.79562V9.16562M6 15.1656L1.26 12.3156M6 15.1656V20.3356M16 15.1656L20.74 12.3156M16 15.1656V20.3356M11 6.66562L6.26 3.81562M11 6.66562L15.74 3.81562'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
