import { syscallFields } from '../../EntryListItem/Entry'

export const bindSyscallInfoRepresentation = (entryData) => {
  let srcSyscallInfoRepresentation = null
  let dstSyscallInfoRepresentation = null

  const srcSyscallInfo = [{
    title: 'Source Syscall Info',
    type: 'table',
    tableData: [],
    body: '',
  }]

  const dstSyscallInfo = [{
    title: 'Destination Syscall Info',
    type: 'table',
    tableData: [],
    body: '',
  }]

  const ignoredSyscallVals = [undefined, null, 0, '']

  syscallFields.forEach(syscallField => {
    if (!ignoredSyscallVals.includes(entryData.data.src[syscallField])) {
      srcSyscallInfo[0].tableData.push({
        name: syscallField,
        selector: `src.${syscallField}`,
        value: entryData.data.src[syscallField]
      })
    }

    if (!ignoredSyscallVals.includes(entryData.data.dst[syscallField])) {
      dstSyscallInfo[0].tableData.push({
        name: syscallField,
        selector: `dst.${syscallField}`,
        value: entryData.data.dst[syscallField]
      })
    }
  })

  if (srcSyscallInfo[0].tableData.length > 0) {
    srcSyscallInfoRepresentation = srcSyscallInfo
  } else {
    srcSyscallInfoRepresentation = null
  }

  if (dstSyscallInfo[0].tableData.length > 0) {
    dstSyscallInfoRepresentation = dstSyscallInfo
  } else {
    dstSyscallInfoRepresentation = null
  }

  entryData.representation.syscallInfo = {
    src: srcSyscallInfoRepresentation ?? null,
    dst: dstSyscallInfoRepresentation ?? null,
  }

  return entryData
}
