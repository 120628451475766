import * as React from 'react';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {
  Button,
  Grid,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  IconButton,
  CircularProgress, Box
} from '@mui/material'

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CodeEditorWrap } from '../../Filters/Filters';
import { HubBaseUrl } from '../../../consts';
import { toast } from 'react-toastify';
import { SyntaxHighlighter } from "../../UI/SyntaxHighlighter";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import queryBuildAtom from "../../../recoil/queryBuild";
import createRecordingDialogOpenAtom from "../../../recoil/createRecordingDialogOpen";

import variables from '../../../variables.module.scss'
import styles from './StartRecordingJobView.module.sass'
import { ClearIcon, TimeField } from '@mui/x-date-pickers';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded'
import pluralize from 'pluralize'
import { useCallback } from 'react'
import Tooltip from '@mui/material/Tooltip'
import timezoneAtom from '../../../recoil/timezone'
import { Utils } from '../../../helpers/Utils'

interface RecordingParams {
  name: string
  query: string
  cron: string
  duration: number
  deleteAfter: number
  limit: number
}

const fieldTexts = {
  name: {
    helper: 'Non-empty, lowercase string without special characters',
    error: 'Name cannot be empty'
  },
  query: {
    helper: 'Empty statement matches everything.',
    error: 'KFL Statement is invalid'
  },
  startTime: {
    helper: 'Leave empty to start now (note: final time applies in UTC+00:00)',
    error: 'Start time is invalid'
  },
  cron: {
    helper: 'Cron statement of the job',
    error: 'Cron statement cannot be empty'
  },
  duration: {
    helper: 'Job will continue for this duration (0 - forever)',
    error: 'Duration must be greater or equal to 0'
  },
  deleteAfter: {
    helper: 'Folder will be deleted after this time (0 - do not delete)',
    error: 'Delete after must be greater or equal to 0'
  },
  limit: {
    helper: '(0 - forever, 1 - once, n - number of days)',
    disabled: 'If start time is empty, job executes once',
    error: 'Limit must be greater or equal to 0'
  }
}

const useImmediateState = (initialValue) => {
  const [value, setValue] = React.useState(initialValue)

  const setImmediateValue = (newValue) => {
    setValue(newValue);
    return newValue;
  }

  return [value, setImmediateValue];
}

const defaultCron = '* * * * * *';
const defaultDurationMins = 60
const defaultDeleteAfterMins = 2880
const defaultLimit = 1

const getDefaultStartTime = () => {
  return dayjs(new Date()).add(5, 'minutes')
}

export const StartRecordingJobView: React.FC = () => {
  const queryBuild = useRecoilValue(queryBuildAtom);
  const setQueryBuild = useSetRecoilState(queryBuildAtom);
  const timezoneStr = useRecoilValue(timezoneAtom)

  const [dialogOpen, setDialogOpen] = useRecoilState(createRecordingDialogOpenAtom)

  const [name, setName] = React.useState('');
  const [nameEdited, setNameEdited] = React.useState(false)

  const [startTime, setStartTime] = React.useState<Dayjs | null>(getDefaultStartTime());

  const [cron, setCron] = React.useState(defaultCron);
  const [durationMins, setDurationMins] = React.useState(defaultDurationMins);
  const [deleteAfterMins, setDeleteAfterMins] = React.useState(defaultDeleteAfterMins);
  const [limit, setLimit] = React.useState(defaultLimit);

  const [createButtonClicked, setCreateButtonClicked] = React.useState(false)

  const autoGenerateName = useCallback(() => {
    const startTimeSuffix = startTime ? `${startTime.format('MM/DD/YYYY')}_at_${startTime.format('HH:mm:ss')}utc_` : ''

    let autoGeneratedName = `${startTimeSuffix}`
    autoGeneratedName += limit > 0 ? `for_${limit}_${pluralize('day', limit)}` : 'forever'
    autoGeneratedName += `_lasting_${durationMins > 0 ? `${durationMins}${pluralize('min', durationMins)}` : 'all_day'}`
    autoGeneratedName = autoGeneratedName.toLocaleLowerCase().replace(/[^a-zA-Z0-9]/g,'_')

    return autoGeneratedName
  }, [startTime, durationMins, limit])

  React.useEffect(() => {
    if (nameEdited) {
      return
    }

    const autoGeneratedName = autoGenerateName()
    setName(autoGeneratedName)
  }, [startTime, durationMins, limit])

  React.useEffect(() => {
    let resolvedCron = ''

    if (startTime === null) {
      resolvedCron = `* * * * * *`
      setLimit(1)
      setIsLimitValid(true)
      setIsLimitDisabled(true)
    } else if (startTime.isValid()) {
      const utcStartTime = startTime.utc()
      resolvedCron = `0 ${utcStartTime.minute()} ${utcStartTime.hour()} * * *`
      setIsLimitDisabled(false)
    }

    setCron(resolvedCron)
  }, [startTime])

  React.useEffect(() => {
    if (dialogOpen) {
      setStartTime(getDefaultStartTime())
      setCron(defaultCron);
      setDurationMins(defaultDurationMins);
      setDeleteAfterMins(defaultDeleteAfterMins);
      setLimit(defaultLimit);

      setIsNameValid(true);
      setIsDurationMinsValid(true);
      setIsDeleteAfterMinsValid(true);
      setIsLimitValid(true);
    }

  }, [dialogOpen, timezoneStr])

  const [isNameValid, setIsNameValid] = useImmediateState(true);
  const [isQueryValid, setIsQueryValid] = useImmediateState(true);
  const [isStartTimeValid, setIsStartTimeValid] = useImmediateState(true);
  const [isDurationMinsValid, setIsDurationMinsValid] = useImmediateState(true);
  const [isDeleteAfterMinsValid, setIsDeleteAfterMinsValid] = useImmediateState(true);
  const [isLimitValid, setIsLimitValid] = useImmediateState(true)

  const [isLimitDisabled, setIsLimitDisabled] = React.useState(false)

  const validateFields = (): number => {
    let issues = 0;

    if (!setIsNameValid(name.trim() !== '')) {
      issues++;
    }
    if (!isQueryValid) {
      issues++;
    }

    if (!isStartTimeValid) {
      issues++;
    }

    if (!setIsDurationMinsValid(durationMins >= 0)) {
      issues++;
    }
    if (!setIsDeleteAfterMinsValid(deleteAfterMins >= 0)) {
      issues++;
    }
    if (!setIsLimitValid(limit >= 0)) {
      issues++;
    }

    return issues;
  }

  const createRecord = () => {
    const issues = validateFields();
    if (issues > 0) {
      toast.error(`Cannot create recording. Fix the ${issues} issues in the form before submitting.`, {
        theme: 'colored'
      });
      return false;
    }

    setCreateButtonClicked(true)

    const [durationMs, deleteAfterMs] = [
      durationMins * 60 * 1000,
      deleteAfterMins * 60 * 1000
    ]

    const recordingParams: RecordingParams = {
      name: name,
      query: queryBuild,
      cron: cron,
      duration: durationMs,
      deleteAfter: deleteAfterMs,
      limit: limit
    }

    let responseStatus: number;

    fetch(
      `${HubBaseUrl}/records`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Kubeshark-Capture': 'ignore',
        },
        body: JSON.stringify(recordingParams)
      }
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then(response => {
        responseStatus = response.status;
        return response.json();
      })
      .then(data => {
        if (responseStatus === 200) {
          if (data.isNewScript) {
            toast.success(`Recording "${name}" created successfully.`, {
              theme: 'colored'
            });

            setDialogOpen(false);
          } else {
            toast.info(`Recording "${name}" already exists. Script was updated.`, {
              theme: 'colored'
            });
          }
        } else {
          toast.error(`Failed to create a new recording script!`, {
            theme: 'colored'
          });
        }
      })
      .then(() => setCreateButtonClicked(false))
      .catch((err) => {
        console.error(err);
        toast.error(err.toString(), {
          theme: 'colored'
        });
      });
  }

  const handleClose = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <DialogContent>
        <Box
          display='flex'
          alignItems='flex-start'
          gap='10px'
          width='100%'
        >
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Name'
            type='text'
            fullWidth
            variant='outlined'
            error={!isNameValid}
            value={name}
            helperText={
              isNameValid
                ? fieldTexts.name.helper
                : fieldTexts.name.error
            }
            required={true}
            onChange={(event) => {
              const nameInput = event.target.value.toLocaleLowerCase().replace(/[^a-zA-Z0-9]/g,'_');
              setNameEdited(true)
              setName(nameInput);
              setIsNameValid(nameInput.trim() !== '');
            }}
            sx={{ flex: 1 }}
          />
          {nameEdited && (
            <Tooltip title='Auto-generate record name' placement='top-end' arrow>
              <Button
                variant='outlined'
                onClick={() => {
                  const autoGeneratedName = autoGenerateName()
                  setName(autoGeneratedName)
                  setNameEdited(false)
                }}
                sx={{
                  mt: '8px',
                  padding: '15px',
                }}
              >
                <AutoFixHighRoundedIcon />
              </Button>
            </Tooltip>
          )}
        </Box>
        <div className={styles.KflSyntaxReferenceContainer}>
          <DialogContentText>
            KFL Statement
          </DialogContentText>
          <Typography id="modal-modal-description" style={{ fontSize: 12, fontStyle: 'italic'}}>
            <Link
              href="https://docs.kubeshark.co/en/filtering#kfl-syntax-reference"
              underline="hover"
              target="_blank"
              style={{ display: "flex", alignItems: "center", gap: "5px"}}
            >
              <MenuBookIcon sx={{ fontSize: 16 }}></MenuBookIcon>
              <b>KFL Syntax Reference</b>
            </Link>
          </Typography>
        </div>
        <div id={styles.KflQueryFieldContainer} style={{margin: "10px 0"}}>
          <CodeEditorWrap
            onQueryChange={(q) => { setQueryBuild(q?.trim()); }}
            onValidationChanged={(changes) => { setIsQueryValid(changes.valid) }}
            hideAutoAppliedFilters
          />
        </div>
        {<Typography id="modal-modal-description"
          style={{ fontSize: 12, marginBottom: '10px',
            fontWeight: !isQueryValid ? 500 : 400,
            fontStyle: !isQueryValid ? 'normal' : 'italic',
            color: !isQueryValid ? variables.failureColor : variables.fontColor }}>
          {!isQueryValid ? fieldTexts.query.error : queryBuild.trim().length === 0 ? fieldTexts.query.helper : ''}
        </Typography>}

        <Grid container spacing={2} wrap='nowrap'>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                margin='dense'
                id='startTime'
                label={`Start time (HH:mm) (UTC${Utils.TimezoneMoment(timezoneStr).format('Z')})`}
                value={startTime}
                timezone={timezoneStr}
                fullWidth
                onChange={(value) => setStartTime(value)}
                format="HH:mm"
                onError={(error) => setIsStartTimeValid(error === null)}
                helperText={
                  isStartTimeValid ?
                    fieldTexts.startTime.helper :
                    fieldTexts.startTime.error
                }
                InputProps={{
                  endAdornment:
                  <IconButton onClick={
                    () => setStartTime(startTime === null ? getDefaultStartTime() : null)
                  }
                  >
                    {
                      startTime === null ? <RestartAltIcon titleAccess='Reset to default' /> :
                        <ClearIcon titleAccess='Set to empty' />
                    }
                  </IconButton>
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              id='limit'
              label='Daily iterations'
              type='number'
              fullWidth
              value={limit}
              variant='outlined'
              error={!isLimitValid}
              helperText={(() => {
                if (isLimitDisabled) {
                  return fieldTexts.limit.disabled
                }

                if (isLimitValid) {
                  return fieldTexts.limit.helper
                } else {
                  return fieldTexts.limit.error
                }
              })()}
              required={true}
              disabled={isLimitDisabled}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(event) => {
                const strValue = event.target.value.replace(/^0+(?=\d)/, '');
                event.target.value = strValue;

                const value = parseInt(strValue) || 0;

                setLimit(value);
                setIsLimitValid(value >= 0);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} wrap='nowrap'>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              id='duration'
              label='Duration (minutes)'
              type='number'
              fullWidth
              value={durationMins}
              variant='outlined'
              error={!isDurationMinsValid}
              helperText={
                isDurationMinsValid
                  ? fieldTexts.duration.helper
                  : fieldTexts.duration.error
              }
              required={true}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(event) => {
                const strValue = event.target.value.replace(/^0+(?=\d)/, '');
                event.target.value = strValue;

                const value = parseInt(strValue) || 0

                setDurationMins(value);
                setIsDurationMinsValid(value >= 0);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin='dense'
              id='deleteAfter'
              label='Expiration (minutes)'
              type='number'
              fullWidth
              value={deleteAfterMins}
              variant='outlined'
              error={!isDeleteAfterMinsValid}
              helperText={
                isDeleteAfterMinsValid
                  ? fieldTexts.deleteAfter.helper
                  : fieldTexts.deleteAfter.error
              }
              required={true}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(event) => {
                const strValue = event.target.value.replace(/^0+(?=\d)/, '');
                event.target.value = strValue;

                const value = parseInt(strValue) || 0

                setDeleteAfterMins(value);
                setIsDeleteAfterMinsValid(value >= 0);
              }}
            />
          </Grid>
        </Grid>

        <DialogContentText>
          Hint:
        </DialogContentText>

        <SyntaxHighlighter
          showLineNumbers={false}
          code={`record("${name}")`}
          language="python"
        />
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <Button
          variant='outlined'
          size='medium'
          style={{
            borderColor: variables.blueColor,
            color: variables.blueColor
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          size='medium'
          onClick={createButtonClicked ? null : createRecord}
          startIcon={createButtonClicked ? (
            <CircularProgress size={14} sx={{
              color: variables.mainBackgroundColor,
              mr: '10px'
            }} />
          ) : null}
          style={{
            backgroundColor: variables.blueColor,
            opacity: createButtonClicked ? 0.8 : 1,
            pointerEvents: createButtonClicked ? 'none' : 'all',
          }}
        >
          {createButtonClicked ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </>
  )
}
