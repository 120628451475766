import React, { useEffect, useRef, useState } from 'react'

import { Box, Button, CircularProgress, IconButton, Modal, Typography } from '@mui/material'

import variables from '../../../variables.module.scss'
import styles from './UnsetLicenseModal.module.sass'

import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded'
import ErrorIcon from '@mui/icons-material/Error'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { HubBaseUrl } from '../../../consts'
import authSessionLogoutAtom from '../../../recoil/authSessionLogout/atom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { toast } from 'react-toastify'
import authSessionLogoutCompletedAtom from '../../../recoil/authSessionLogoutCompleted/atom'

interface UnsetLicenseModalProps {
  open: boolean
  setOpen: (state: boolean) => void
  handleConfirmed: () => void
  handleCancelled: () => void
}

export const UnsetLicenseModal: React.FC<UnsetLicenseModalProps> = ({open, setOpen, handleConfirmed, handleCancelled}) => {
  const [removeLicenseBtnClicked, setRemoveLicenseBtnClicked] = useState(false)

  const handleCloseModal = () => setOpen(false)

  const setAuthSessionLogout = useSetRecoilState(authSessionLogoutAtom)
  const authSessionLogoutCompleted = useRecoilValue(authSessionLogoutCompletedAtom)

  const renderComplete = useRef({ authSessionLogoutCompleted: false})

  useEffect(() => {
    if (renderComplete.current['authSessionLogoutCompleted']) {
      if (authSessionLogoutCompleted !== null) {
        handleConfirmed()
      }
    }

    renderComplete.current['authSessionLogoutCompleted'] = true
  }, [authSessionLogoutCompleted])

  const removeLicense = () => {
    setRemoveLicenseBtnClicked(true)

    fetch(
      `${HubBaseUrl}/license/remove`,
      {
        method: 'POST',
        headers: {
          'X-Kubeshark-Capture': 'ignore',
        },
      },
    )
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then((response) => {
        if (response.status === 200) {
          setAuthSessionLogout(Date.now())
          setOpen(false)
        }
      }).then(() => setRemoveLicenseBtnClicked(false))
      .catch(() => {
        toast.error(`Failed to remove a license!`, {
          theme: 'colored'
        })
      })
  }

  const cancel = () => {
    setOpen(false)
    handleCancelled()
  }

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box className={styles.UnsetLicenseModal}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <RotateLeftRoundedIcon htmlColor={variables.lightBlueColor} sx={{ fontSize: '42px' }} />
            <ErrorIcon fontSize='medium' htmlColor={variables.upgradeColor} sx={{ position: 'absolute', bottom: "-3px", right: "-5px" }} />
          </Box>
          <IconButton aria-label="delete" onClick={handleCloseModal}>
            <CloseRoundedIcon htmlColor={variables.grayColor} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Typography variant='h3' fontFamily={variables.textFontFamily} fontWeight={700} color={variables.fontColor}>
            Remove license
          </Typography>
          <Typography variant='h6' fontFamily={variables.textFontFamily} fontWeight={600} color={variables.secondaryFontColor}>
            Please confirm your action
          </Typography>
        </Box>
        <Typography variant='body1' fontFamily={variables.textFontFamily} color={variables.fontColor}>
          You are about to remove the current license. <br />The license will be removed and you will be automatically logged out.
        </Typography>
        <Box width='100%' display='flex' alignItems='center' justifyContent='flex-end' gap='15px'>
          <Button
            className='themeButton secondary'
            color='primary'
            variant='outlined'
            size='medium'
            sx={{ bgcolor: variables.failureColor, mt: '10px'}}
            onClick={cancel}
          >
            <Typography
              variant='body1'
              fontSize={14}
              fontFamily={variables.textFontFamily}
              fontWeight={600}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            className='themeButton primary'
            color='primary'
            variant='contained'
            size='medium'
            startIcon={
              removeLicenseBtnClicked ?
                <CircularProgress size={16} sx={{ color: variables.mainBackgroundColor, mr: '5px' }} /> :
                <RotateLeftRoundedIcon sx={{ marginRight: '5px' }} />
            }
            sx={{
              bgcolor: variables.failureColor,
              mt: '10px',
              opacity: removeLicenseBtnClicked ? 0.8 : 1,
              pointerEvents: removeLicenseBtnClicked ? 'none' : 'all',
            }}
            onClick={removeLicenseBtnClicked ? null : removeLicense}
          >
            <Typography
              variant='body1'
              fontSize={14}
              fontFamily={variables.textFontFamily}
              fontWeight={600}
            >
              {removeLicenseBtnClicked ? 'Removing License...' : 'Remove License'}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
