import React from 'react'
import variables from '../../../variables.module.scss'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { Fab } from '@mui/material'

interface GoToScriptButtonProps {
  onClick: () => void
}

export const ShowNewLogsButton: React.FC<GoToScriptButtonProps> = ({ onClick }) => {
  return (
    <Fab
      variant='extended'
      color='default'
      size='small'
      onClick={onClick}
      sx={{
        paddingLeft: '20px',
        paddingRight: '23px',
        backgroundColor: variables.darkSlateColor,
        color: variables.lightestGrayColor,
        '&:hover': {
          backgroundColor: variables.githubEditorBackgroundColorLight,
        }
      }}
    >
      <VisibilityRoundedIcon
        htmlColor={variables.lightestGrayColor}
        sx={{
          mr: '12px',
          fontSize: '18px',
        }}
      />
      <span
        style={{
          fontSize: '14px',
          fontWeight: 500,
          textTransform: 'initial',
          letterSpacing: '0.01em',
        }}
      >
        Go to latest
      </span>
    </Fab>
  )
}
