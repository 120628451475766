import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react'
import {
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  DialogContentText,
  Checkbox,
  Box,
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ForwardIcon from '@mui/icons-material/Forward';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MultipleStopRoundedIcon from '@mui/icons-material/MultipleStopRounded';
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded';
import { GraphData, Node, Edge, LegendData, ElementPosition } from "./ServiceMapModalTypes"
import ServiceMapOptions from './ServiceMapOptions'
import ForceGraph from "./ForceGraph";
import Moment from "moment";
import { useRecoilValue } from "recoil";

import { Utils } from '../../../helpers/Utils'

import Queryable from "../../UI/Queryable/Queryable";
import NodeTypes from "./NodeTypes";
import EdgeTypes from "./EdgeTypes";
import {composeNodeFilter, EndpointType} from "./NodeFilters";
import composeEdgeFilter from "./EdgeFilters";

import entriesAtom from "../../../recoil/entries";
import { FullscreenViewButton } from "../../UI/FullscreenView/FullscreenViewButton";
import fullscreenViewAtom from "../../../recoil/fullscreenView/atom";
import useStreamingWebSocket from '../../../hooks/useStreamingWebSocket'
import { useInterval } from '../../../helpers/interval'
import variables from '../../../variables.module.scss'
import { CheckboxIcon } from './components/CheckboxIcon'
import { ResourceNameIcon } from '../../UI/Icons/ResourceNameIcon'
import { PodIcon } from '../../UI/Icons/PodIcon'
import { EndpointSliceIcon } from '../../UI/Icons/EndpointSliceIcon'
import { ServiceIcon } from '../../UI/Icons/ServiceIcon'
import { NamespaceIcon } from '../../UI/Icons/NamespaceIcon'
import { NodeIcon } from '../../UI/Icons/NodeIcon'
import {
  MapOutlined,
  SettingsOutlined,
} from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { useNodeBackground } from './useNodeBackground'

/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 *
 * @param {Int} num The number of bytes.
 */
function humanReadableBytes(bytes): string {
  let i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i < 0) i = 0;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const result = (bytes / Math.pow(1024, i)).toFixed(2);
  return result + ' ' + sizes[i];
}

export const ServiceMap: React.FC = () => {
  const [edgeType, setEdgeType] = useState("bandwidth");
  const [nodeType, setNodeType] = useState("name");

  const [graphData, setGraphData] = useState<GraphData>({ nodes: [], edges: [] });
  const [graphOptions, setGraphOptions] = useState(ServiceMapOptions);
  const [colorGroups, setColorGroups] = useState({})

  const [legendData, setLegendData] = useState<LegendData>({});

  const [selectedEdges, setSelectedEdges] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);

  const [selectingFromHighlight, setSelectingFromHighlight] = useState(false);
  const [hoveredElementPosition, setHoveredElementPosition] = useState<ElementPosition>({x: 0, y: 0})

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipQuery, setTooltipQuery] = useState("");

  const [showCumulative, setShowCumulative] = React.useState(false);
  const [showRequests, setShowRequests] = React.useState(true);
  const [showResponses, setShowResponses] = React.useState(true);

  const [maximizeOptionsCard, setMaximizeOptionsCard] = React.useState(true);
  const [maximizeLegendCard, setMaximizeLegendCard] = React.useState(true);

  const [mapDragged, setMapDragged] = React.useState(false);

  const entries = useRecoilValue(entriesAtom)
  const fullscreenView = useRecoilValue(fullscreenViewAtom)

  const { readyState } = useStreamingWebSocket()
  const { assignNodeBackground } = useNodeBackground()

  const [renderClock, setRenderClock] = useState(0)

  useInterval(useCallback(() => {
    if (readyState === WebSocket.OPEN) {
      setRenderClock(Date.now())
    }
  }, [readyState]), 1000, true)

  useEffect(() => {
    const nodeMap = {};
    const edgeMap = {};
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const legendMap = {};

    let firstMoment: Moment.Moment;

    [...entries.values()].map(entry => {
      const thisMoment = Moment(+entry.timestamp)?.utc();
      if (firstMoment === undefined) firstMoment = thisMoment;
      if (thisMoment.diff(firstMoment, "seconds") < 0) firstMoment = thisMoment;

      let srcLabel = entry.src.name;
      let dstLabel = entry.dst.name;
      let srcKey = `${entry.src.name}.${entry.src.namespace}`;
      let dstKey = `${entry.dst.name}.${entry.dst.namespace}`;

      let srcName = "";
      let dstName = "";
      let srcVerb = "";
      let dstVerb = "";

      switch (nodeType) {
      case NodeTypes.Name:
        if (entry.src.pod) {
          srcVerb = NodeTypes.Pod;
          srcName = entry.src.pod.metadata.name;
        } else if (entry.src.endpointSlice) {
          srcVerb = NodeTypes.EndpointSlice;
          srcName = entry.src.endpointSlice.metadata.name;
        } else if (entry.src.service) {
          srcVerb = NodeTypes.Service;
          srcName = entry.src.service.metadata.name;
        }
        break;
      case NodeTypes.Namespace:
        if (entry.src.pod) {
          srcLabel = entry.src.pod.metadata.namespace;
        } else if (entry.src.endpointSlice) {
          srcLabel = entry.src.endpointSlice.metadata.namespace;
        } else if (entry.src.service) {
          srcLabel = entry.src.service.metadata.namespace;
        }
        srcKey = srcLabel;

        srcVerb = NodeTypes.Namespace;
        srcName = srcLabel;
        break;
      case NodeTypes.Pod:
        if (entry.src.pod) {
          srcLabel = entry.src.pod.metadata.name;
          srcKey = `${entry.src.pod.metadata.name}.${entry.src.pod.metadata.namespace}`;
          srcName = entry.src.pod.metadata.name;
        }

        srcVerb = NodeTypes.Pod;
        break;
      case NodeTypes.EndpointSlice:
        if (entry.src.endpointSlice) {
          srcLabel = entry.src.endpointSlice.metadata.name;
          srcKey = `${entry.src.endpointSlice.metadata.name}.${entry.src.endpointSlice.metadata.namespace}`;
          srcName = entry.src.endpointSlice.metadata.name;
        }

        srcVerb = NodeTypes.EndpointSlice;
        break;
      case NodeTypes.Service:
        if (entry.src.service) {
          srcLabel = entry.src.service.metadata.name;
          srcKey = `${entry.src.service.metadata.name}.${entry.src.service.metadata.namespace}`;
          srcName = entry.src.service.metadata.name;
        }

        srcVerb = NodeTypes.Service;
        break;
      }

      switch (nodeType) {
      case NodeTypes.Name:
        if (entry.dst.pod) {
          dstVerb = NodeTypes.Pod;
          dstName = entry.dst.pod.metadata.name;
        } else if (entry.dst.endpointSlice) {
          dstVerb = NodeTypes.EndpointSlice;
          dstName = entry.dst.endpointSlice.metadata.name;
        } else if (entry.dst.service) {
          dstVerb = NodeTypes.Service;
          dstName = entry.dst.service.metadata.name;
        }
        break;
      case NodeTypes.Namespace:
        if (entry.dst.pod) {
          dstLabel = entry.dst.pod.metadata.namespace;
        } else if (entry.dst.endpointSlice) {
          dstLabel = entry.dst.endpointSlice.metadata.namespace;
        } else if (entry.dst.service) {
          dstLabel = entry.dst.service.metadata.namespace;
        }
        dstKey = dstLabel;

        dstVerb = NodeTypes.Namespace;
        dstName = dstLabel;
        break;
      case NodeTypes.Pod:
        if (entry.dst.pod) {
          dstLabel = entry.dst.pod.metadata.name;
          dstKey = `${entry.dst.pod.metadata.name}.${entry.dst.pod.metadata.namespace}`;
          dstName = entry.dst.pod.metadata.name;
        }

        dstVerb = NodeTypes.Pod;
        break;
      case NodeTypes.EndpointSlice:
        if (entry.dst.endpointSlice) {
          dstLabel = entry.dst.endpointSlice.metadata.name;
          dstKey = `${entry.dst.endpointSlice.metadata.name}.${entry.dst.endpointSlice.metadata.namespace}`;
          dstName = entry.dst.endpointSlice.metadata.name;
        }

        dstVerb = NodeTypes.EndpointSlice;
        break;
      case NodeTypes.Service:
        if (entry.dst.service) {
          dstLabel = entry.dst.service.metadata.name;
          dstKey = `${entry.dst.service.metadata.name}.${entry.dst.service.metadata.namespace}`;
          dstName = entry.dst.service.metadata.name;
        }

        dstVerb = NodeTypes.Service;
        break;
      }

      switch (nodeType) {
      case NodeTypes.WorkerNode:
        if (entry.src.pod && entry.dst.pod) {
          srcLabel = entry.src.pod.spec.nodeName
          srcKey = entry.src.pod.spec.nodeName
          srcName = entry.src.pod.spec.nodeName

          dstLabel = entry.dst.pod.spec.nodeName
          dstKey = entry.dst.pod.spec.nodeName
          dstName = entry.dst.pod.spec.nodeName
        } else {
          srcKey = ""
          dstKey = ""
        }

        srcVerb = NodeTypes.WorkerNode
        dstVerb = NodeTypes.WorkerNode

        break;
      }

      if (srcLabel.length === 0) {
        srcLabel = entry.src.ip;
      }
      if (dstLabel.length === 0) {
        dstLabel = entry.dst.ip;
      }

      let srcId: number;
      let dstId: number;

      const keyArr: string[] = [srcKey, dstKey].filter((keyElem) => keyElem.length > 0);
      const labelArr: string[] = [srcLabel, dstLabel];
      const nameArr: string[] = [srcName, dstName];
      const namespaceArr: string[] = [entry.src.namespace, entry.dst.namespace];
      const verbArr: string[] = [srcVerb, dstVerb];
      for (let i = 0; i < keyArr.length; i++) {
        const nodeKey: string = keyArr[i];
        let node: Node;
        const namespace = namespaceArr[i];

        if (!(namespace in colorGroups) && namespace?.length > 0) {
          const colorSet = {
            background: assignNodeBackground(namespace),
            border: variables.grayColor
          }

          const newGroup = {
            key: namespace,
            font: {
              color: variables.fontColor,
              background: 'rgba(255,255,255,0.85)',
            },
            color: {
              highlight: {...colorSet, border: variables.blackColor},
              hover: {...colorSet, border: variables.blackColor},
              ...colorSet
            },
            filter: `src.namespace == "${namespace}" or dst.namespace == "${namespace}"`,
          }

          setColorGroups(prevGroups => {
            const updatedGroups = {...prevGroups}
            updatedGroups[namespace] = newGroup
            return updatedGroups
          })

          setGraphOptions(prevOptions => {
            const updatedOptions = {...prevOptions}
            updatedOptions.groups[namespace] = newGroup
            return updatedOptions
          })
        }

        if (nodeKey in nodeMap) {
          node = nodeMap[nodeKey]
          nodeMap[nodeKey].value++;
        } else {
          let endpointType: EndpointType = null

          if (i === 0) {
            endpointType = EndpointType.Source
          } else if (i === 1) {
            endpointType = EndpointType.Destination
          }

          node = {
            id: nodes.length,
            value: 1,
            label: `${labelArr[i]}`,
            filter: composeNodeFilter(nodeType, entry, endpointType),
            group: namespace,
            title: nodeKey,
            name: nameArr[i],
            namespace: namespace,
            verb: verbArr[i],
            color: colorGroups[namespace]?.color ?? {
              background: variables.lightGrayColor,
              border: variables.lightGrayColor,
            },
            chosen: {
              node: (values) => {
                values.borderWidth = 3
                values.size = values.size + 20
              },
              label: (values) => {
                values.size = values.size + 10
              }
            }
          };

          nodes.push(node);
          nodeMap[nodeKey] = node;
        }

        if (i == 0)
          srcId = node.id;
        else
          dstId = node.id;
      }

      const edgeKey = `${entry.proto.abbr}_${srcId}_${dstId}`;

      let edge: Edge;
      if (edgeKey in edgeMap) {
        edge = edgeMap[edgeKey];
        if (edge.dashes === false) edge.dashes = entry.error !== null;
      } else {
        edge = {
          id: edges.length,
          from: srcId,
          to: dstId,
          value: 1,
          count: 1,
          cumulative: 1,
          label: "",
          filter: composeEdgeFilter(nodeType, entry),
          protoFull: entry.proto,
          proto: entry.proto.abbr,
          title: entry.proto.longName,
          color: entry.proto.backgroundColor,
          dashes: entry.error !== null,
          chosen: {
            label: (values) => {
              values.size = 24
            }
          }
        }
        edgeMap[edgeKey] = edge;
        edges.push(edge);
      }

      const secondsPassed = thisMoment.diff(firstMoment, "seconds");

      switch (edgeType) {
      case EdgeTypes.Bandwidth:
        if (showRequests)
          edgeMap[edgeKey].cumulative += entry.requestSize;
        if (showResponses)
          edgeMap[edgeKey].cumulative += entry.responseSize;

        if (showCumulative)
          edgeMap[edgeKey].value = edgeMap[edgeKey].cumulative;
        else if (secondsPassed > 0)
          edgeMap[edgeKey].value = edgeMap[edgeKey].cumulative / secondsPassed;

        edgeMap[edgeKey].label = Utils.isReadableNumber(edgeMap[edgeKey].value) ?
          `<b>${humanReadableBytes(edgeMap[edgeKey].value)}${!showCumulative ? '/s' : ''}</b>` : ''

        break;
      case EdgeTypes.Throughput:
        edgeMap[edgeKey].cumulative++;

        if (showCumulative)
          edgeMap[edgeKey].value = edgeMap[edgeKey].cumulative;
        else if (secondsPassed > 0)
          edgeMap[edgeKey].value = Math.round(edgeMap[edgeKey].cumulative / secondsPassed * 100) / 100;

        edgeMap[edgeKey].label = Utils.isReadableNumber(edgeMap[edgeKey].value) ?
          `<b>${edgeMap[edgeKey].value}</b>${!showCumulative ? '/s' : ''}` : '';

        break;
      case EdgeTypes.Latency:
        edgeMap[edgeKey].value = Math.round((entry.elapsedTime + edgeMap[edgeKey].value * edgeMap[edgeKey].count) / (edgeMap[edgeKey].count + 1) * 100) / 100;
        edgeMap[edgeKey].label = Utils.isReadableNumber(edgeMap[edgeKey].value) ?
          `<b>${edgeMap[edgeKey].value} μs</b>` : '';
        break;
      }

      edgeMap[edgeKey].count++;
    });

    setGraphData({
      nodes: nodes,
      edges: edges,
    });


    edges.forEach(edge => {
      if (!legendMap[edge.protoFull.abbr]) {
        legendMap[edge.protoFull.abbr] = edge.protoFull
      }
    })

    const legendMapSorted = Object.keys(legendMap).sort().reduce(
      (obj, key) => {
        obj[key] = legendMap[key];
        return obj;
      },
      {}
    );

    setLegendData(legendMapSorted);
  }, [renderClock, colorGroups, edgeType, nodeType]);

  useEffect(() => {
    if (graphData?.nodes?.length === 0) return;
    const options = { ...graphOptions };
    setGraphOptions(options);
  }, [graphData?.nodes?.length]);

  const handleEdgeChange = (event: SelectChangeEvent) => {
    setSelectedEdges([]);
    setGraphData({
      nodes: [],
      edges: [],
    });
    setEdgeType(event.target.value as string);

    if (graphNetworkApi.current) {
      graphNetworkApi.current.fit()
    }
  };

  const handleNodeChange = (event: SelectChangeEvent) => {
    setSelectedNodes([]);
    setGraphData({
      nodes: [],
      edges: [],
    });
    setNodeType(event.target.value as string);

    if (graphNetworkApi.current) {
      graphNetworkApi.current.fit()
    }
  };

  const events = {
    select: ({nodes, edges}) => {
      setSelectedEdges(edges);
      setSelectedNodes(nodes);
    },
    hoverNode: ({node: nodeId, event}) => {
      if (selectingFromHighlight) {
        return
      }

      setTooltipQuery(graphData.nodes[nodeId].filter)
      setHoveredElementPosition({x: event.offsetX, y: event.offsetY})
      setShowTooltip(true)
    },
    blurNode: () => {
      if (selectingFromHighlight) {
        return
      }

      setShowTooltip(false)
      setTooltipQuery("")
      setHoveredElementPosition({x: 0, y: 0})
    },
    hoverEdge: ({edge: edgeId, event}) => {
      if (selectingFromHighlight) {
        return
      }

      setTooltipQuery(graphData.edges[edgeId].filter)
      setHoveredElementPosition({x: event.offsetX, y: event.offsetY})
      setShowTooltip(true)
    },
    blurEdge: () => {
      if (selectingFromHighlight) {
        return
      }

      setShowTooltip(false)
      setTooltipQuery("")
      setHoveredElementPosition({x: 0, y: 0})
    }
  }

  const handleShowCumulativeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowCumulative(event.target.checked);
  };

  const handleShowRequestsCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowRequests(event.target.checked);
  };

  const handleShowResponsesCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowResponses(event.target.checked);
  };

  const mapContainerRef = useRef(null);

  const [graphHeight, setGraphHeight] = React.useState(650)

  const graphNetworkApi = useRef(null)

  function setGraphNetworkApi(networkApi) {
    graphNetworkApi.current = networkApi
  }

  const handleResize = () => {
    setGraphHeight(mapContainerRef.current.clientHeight)
    if (graphNetworkApi.current) {
      graphNetworkApi.current.fit()
    }
  };

  useLayoutEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setGraphHeight(mapContainerRef.current.clientHeight)

    if (graphNetworkApi.current) {
      graphNetworkApi.current.fit()
    }
  }, [fullscreenView])

  useEffect(() => {
    setTimeout(() => {
      if (graphNetworkApi.current) {
        graphNetworkApi.current.fit()
      }
    }, 300)
  }, [graphNetworkApi.current])

  useEffect(() => {
    if (!graphNetworkApi.current) {
      return
    }

    if (graphData.nodes.length > 0) {
      if (selectedNodes.every(v => graphNetworkApi.current.body.data.nodes.getIds().includes(v))) {
        graphNetworkApi.current.selectNodes(selectedNodes)
      } else {
        graphNetworkApi.current.selectNodes([])
      }
    }
  }, [selectedNodes, graphData.nodes])

  useEffect(() => {
    if (!graphNetworkApi.current) {
      return
    }

    if (graphData.edges.length > 0) {
      if (selectedEdges.every(v => graphNetworkApi.current.body.data.edges.getIds().includes(v))) {
        graphNetworkApi.current.selectEdges(selectedEdges)
      } else {
        graphNetworkApi.current.selectEdges([])
      }
    }
  }, [selectedEdges, graphData.edges])

  return (
    <div
      ref={mapContainerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "40vh",
        flexGrow: 1,
        backgroundColor: variables.mainBackgroundColor,
        color: '#000',
        overflow: 'hidden',
        cursor: mapDragged ? 'grabbing' : 'grab',
      }}
      onDoubleClick={() => {
        if (graphNetworkApi.current) {
          graphNetworkApi.current.fit({
            animation: true
          })
        }
      }}
      onMouseDown={() => setMapDragged(true)}
      onMouseUp={() => setMapDragged(false)}
    >
      <Box position='absolute' top='5px' right='5px' zIndex={1}>
        <FullscreenViewButton />
      </Box>
      {!maximizeOptionsCard && (
        <Box position='absolute' bottom='5px' right='5px' zIndex={1}>
          <Tooltip
            title='Map Settings - Switch node/edge types'
            placement='top-end'
            arrow
          >
            <IconButton onClick={() => {
              setMaximizeOptionsCard(true);
            }} style={{
              margin: "2px",
              padding: "4px",
              borderRadius: '4px',
              backgroundColor: variables.blueColor
            }}>
              <SettingsOutlined
                htmlColor={variables.mainBackgroundColor}
                sx={{
                  fontSize: '28px',
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {maximizeOptionsCard && <Card sx={{
        maxWidth: '300px',
        position: "absolute",
        bottom: '5px',
        right: '5px',
        zIndex: 1,
        boxShadow: 'none',
        border: `1px solid ${variables.lightBlueColor}`,
        borderRadius: '6px',
      }}>
        <CardContent sx={{
          padding: '20px',
          paddingBottom: `15px !important`
        }}>
          <Box
            boxSizing='border-box'
            paddingBottom='10px'
            marginBottom='20px'
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            borderBottom={`1px solid ${variables.lightGrayBlueColor}`}
          >
            <span
              style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                color: variables.fontColor
              }}
            >
              Settings
            </span>
            <IconButton onClick={() => {
              setMaximizeOptionsCard(false);
            }} style={{
              padding: "2px",
              border: `1px solid ${variables.lightBlueColor}`,
              borderRadius: '50px',
              backgroundColor: variables.dataBackgroundColor
            }}>
              <CloseRoundedIcon
                htmlColor={variables.blueColor}
                sx={{
                  fontSize: '16px'
                }}
              />
            </IconButton>
          </Box>
          <FormControl fullWidth size="small">
            <span
              style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                marginBottom: '8px',
                color: variables.slateColor,
              }}
            >
              Edges
            </span>
            <Select
              labelId="edge-select-label"
              id="edge-select"
              value={edgeType}
              IconComponent={() => <ExpandMoreRoundedIcon htmlColor={variables.blueColor} sx={{ mr: '5px' }} />}
              onChange={handleEdgeChange}
              sx={{
                borderRadius: '6px',
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${variables.lighterGrayColor}`,
                }
              }}
            >
              <MenuItem value={EdgeTypes.Bandwidth}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <MultipleStopRoundedIcon htmlColor={variables.blueColor} sx={{ fontSize: '20px' }} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>Bandwidth</span>
                </Box>
              </MenuItem>
              <MenuItem value={EdgeTypes.Throughput}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <AvTimerRoundedIcon htmlColor={variables.blueColor} sx={{ fontSize: '20px' }} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>Throughput</span>
                </Box>
              </MenuItem>
              <MenuItem value={EdgeTypes.Latency}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <HistoryToggleOffRoundedIcon htmlColor={variables.blueColor} sx={{ fontSize: '20px' }} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>Latency</span>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth size="small" sx={{
            marginTop: '20px',
            paddingBottom: '10px',
          }}>
            <span
              style={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                marginBottom: '8px',
                color: variables.slateColor,
              }}
            >
              Nodes
            </span>
            <Select
              labelId="node-select-label"
              id="node-select"
              value={nodeType}
              IconComponent={() => <ExpandMoreRoundedIcon htmlColor={variables.blueColor} sx={{ mr: '5px' }} />}
              onChange={handleNodeChange}
              sx={{
                borderRadius: '6px',
                '.MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${variables.lighterGrayColor}`,
                }
              }}
            >
              <MenuItem value={NodeTypes.Name}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <ResourceNameIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Resolved Name
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.Namespace}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <NamespaceIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Namespace
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.Pod}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <PodIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Pod
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.EndpointSlice}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <EndpointSliceIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    EndpointSlice
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.Service}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <ServiceIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Service
                  </span>
                </Box>
              </MenuItem>
              <MenuItem value={NodeTypes.WorkerNode}>
                <Box boxSizing='border-box' display='flex' alignItems='center' gap='10px'>
                  <NodeIcon stroke={variables.blueColor} />
                  <span style={{ fontFamily: 'Source Sans Pro, sans-serif', fontWeight: 600, fontSize: '14px' }}>
                    Node
                  </span>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

          {(edgeType === EdgeTypes.Bandwidth || edgeType === EdgeTypes.Throughput) && <FormControlLabel
            label={
              <DialogContentText sx={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                color: variables.slateColor,
              }}>
                Show cumulative {edgeType === EdgeTypes.Bandwidth ? EdgeTypes.Bandwidth : ""}{edgeType === EdgeTypes.Throughput ? EdgeTypes.Throughput : ""}
              </DialogContentText>
            }
            control={
              <Checkbox
                checked={showCumulative}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxIcon checked />}
                onChange={handleShowCumulativeCheck}
              />
            }
            style={{marginTop: "5px"}}
            labelPlacement="end"
          />}

          {edgeType === EdgeTypes.Bandwidth && <FormControlLabel
            label={
              <DialogContentText sx={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                color: variables.slateColor,
              }}>
                Include request sizes
              </DialogContentText>
            }
            control={
              <Checkbox
                checked={showRequests}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxIcon checked />}
                onChange={handleShowRequestsCheck}
              />
            }
            labelPlacement="end"
          />}

          {edgeType === EdgeTypes.Bandwidth && <FormControlLabel
            label={
              <DialogContentText sx={{
                fontFamily: 'Roboto, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                color: variables.slateColor,
              }}>
                Include response sizes
              </DialogContentText>
            }
            control={
              <Checkbox
                checked={showResponses}
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxIcon checked />}
                onChange={handleShowResponsesCheck}
              />
            }
            labelPlacement="end"
          />}
        </CardContent>
      </Card>}

      {Object.keys(legendData).length > 0 && (
        <>
          {!maximizeLegendCard && (
            <Box position="absolute" bottom="5px" left="5px" zIndex={1}>
              <Tooltip
                title='Map Legend - Protocols/Namespaces'
                placement='top-start'
                arrow
              >
                <IconButton onClick={() => {
                  setMaximizeLegendCard(true)
                }} style={{
                  margin: '2px',
                  padding: '4px',
                  borderRadius: '4px',
                  backgroundColor: variables.blueColor
                }}>
                  <MapOutlined
                    htmlColor={variables.mainBackgroundColor}
                    sx={{
                      fontSize: '28px'
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Card sx={{
            maxWidth: '360px',
            position: 'absolute',
            left: '0.5%',
            bottom: '1%',
            zIndex: 1,
            boxShadow: 'none',
            border: `1px solid ${variables.lightBlueColor}`,
            borderRadius: '4px'
          }}>
            {maximizeLegendCard && <CardContent sx={{ maxHeight: '50vh', overflow: 'auto' }}>
              <Box
                boxSizing="border-box"
                paddingBottom="10px"
                marginBottom="20px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={`1px solid ${variables.lightGrayBlueColor}`}
              >
                <span
                  style={{
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: variables.fontColor
                  }}
                >
                  Legend
                </span>
                <IconButton onClick={() => {
                  setMaximizeLegendCard(false)
                }} style={{
                  padding: '2px',
                  border: `1px solid ${variables.lightBlueColor}`,
                  borderRadius: '50px',
                  backgroundColor: variables.dataBackgroundColor
                }}>
                  <CloseRoundedIcon
                    htmlColor={variables.blueColor}
                    sx={{
                      fontSize: '16px'
                    }}
                  />
                </IconButton>
              </Box>
              {Object.keys(legendData).length > 0 && (
                <ListItem key='protocols-title' disableGutters disablePadding>
                  <Box
                    boxSizing='border-box'
                    display='flex'
                    alignItems='center'
                    gap='10px'
                    marginBottom='15px'
                  >
                    <span
                      style={{
                        margin: 0,
                        width: '15px',
                        height: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4px',
                        textAlign: 'center',
                        fontSize: '12px',
                        fontWeight: 500,
                        fontFamily: 'Roboto, sans-serif',
                        color: variables.mainBackgroundColor,
                        backgroundColor: variables.slateColor
                      }}
                    >
                      {Object.keys(legendData).length}
                    </span>
                    <span
                      style={{
                        fontFamily: 'Roboto, sans-serif',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: variables.slateColor,
                      }}
                    >
                      Protocols
                    </span>
                  </Box>
                </ListItem>
              )}
              <List dense disablePadding>
                {
                  Object.keys(legendData).map(function(key) {
                    const proto = legendData[key]
                    const primaryStyle = {
                      color: proto.backgroundColor,
                      fontFamily: 'Source Sans Pro, sans-serif',
                      fontWeight: 'bold',
                    }
                    const secondaryStyle = {
                      fontSize: '12px',
                      color: proto.backgroundColor,
                    }

                    return <ListItem key={key} disableGutters disablePadding sx={{
                      backgroundColor: variables.lighterGrayBlueColor,
                      padding: '0 5px',
                      marginBottom: '5px',
                      border: `1px solid ${variables.lightGrayBlueColor}`,
                      borderRadius: '4px',
                      '&:hover': {
                        border: `1px solid ${proto.backgroundColor}`
                      },
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      const focusedNodes = []
                      const edges = []
                      graphData.edges.forEach(edge => {
                        if (edge.proto === proto.abbr) {
                          edges.push(edge.id)
                          if (graphNetworkApi) {
                            if (edge.from !== undefined) {
                              focusedNodes.push(edge.from)
                            }
                            if (edge.to !== undefined) {
                              focusedNodes.push(edge.to)
                            }
                          }
                        }
                      })
                      setSelectedNodes([])
                      setSelectedEdges(edges)
                      try {
                        if (graphNetworkApi.current) {
                          graphNetworkApi.current.fit({
                            nodes: focusedNodes,
                            animation: true
                          })
                        }
                      } catch (e) {
                        console.log('Focusing on service map edges failed due to simultaneous map dimensions changing')
                      }

                    }}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <ForwardIcon sx={{ color: proto.backgroundColor }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={proto.abbr}
                        secondary={proto.longName}
                        primaryTypographyProps={{ style: primaryStyle }}
                        secondaryTypographyProps={{ style: secondaryStyle }}
                      />
                    </ListItem>
                  })
                }
                {Object.keys(graphOptions.groups).length > 0 && (
                  <ListItem key='namespaces-title' disableGutters disablePadding>
                    <Box
                      boxSizing='border-box'
                      display='flex'
                      alignItems='center'
                      gap='10px'
                      marginTop='20px'
                      marginBottom='15px'
                    >
                      <span
                        style={{
                          margin: 0,
                          width: '15px',
                          height: '15px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '4px',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 500,
                          fontFamily: 'Roboto, sans-serif',
                          color: variables.mainBackgroundColor,
                          backgroundColor: variables.slateColor
                        }}
                      >
                        {Object.keys(graphOptions.groups).length}
                      </span>
                      <span
                        style={{
                          fontFamily: 'Roboto, sans-serif',
                          fontSize: '14px',
                          fontWeight: 500,
                          color: variables.slateColor,
                        }}
                      >
                        Namespaces
                      </span>
                    </Box>
                  </ListItem>
                )}
                {
                  Object.keys(graphOptions.groups).map(function(key) {
                    if (!key) return;

                    const group = graphOptions.groups[key];
                    const primaryStyle = {
                      color: variables.grayColor,
                      fontFamily: 'Source Sans Pro, sans-serif',
                      fontWeight: 700,
                    };
                    const secondaryStyle = {
                      color: group.color.background,
                    };

                    return <ListItem key={key} disableGutters disablePadding sx={{
                      backgroundColor: variables.lighterGrayBlueColor,
                      padding: '5px 8px',
                      marginBottom: '5px',
                      border: `1px solid ${variables.lightGrayBlueColor}`,
                      borderRadius: '4px',
                      '&:hover': {
                        border: `1px solid ${group.color.background ?? variables.lightGrayColor}`
                      },
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      const nodes = []
                      graphData.nodes.forEach(node => {
                        if (node.group === group.key) {
                          nodes.push(node.id)
                        }
                      })
                      setSelectedEdges([])
                      setSelectedNodes(nodes)
                      try {
                        if (graphNetworkApi.current) {
                          graphNetworkApi.current.fit({
                            nodes,
                            animation: true
                          })
                        }
                      } catch (e) {
                        console.log('Focusing on service map nodes failed due to simultaneous map dimensions changing')
                      }
                    }}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <CircleIcon sx={{
                          fontSize: '18px',
                          color: group.color.background ?? variables.lightGrayColor,
                          borderRadius: '50px',
                          border: `1px solid ${group.color.background ?? variables.lightGrayColor}`
                        }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={key}
                        primaryTypographyProps={{ style: primaryStyle }}
                        secondaryTypographyProps={{ style: secondaryStyle }}
                      />
                    </ListItem>
                  })
                }
              </List>
            </CardContent>}
          </Card>
        </>
      )}
      {
        graphData.nodes.length > 0 && graphData.edges.length > 0 && <ForceGraph
          getNetwork={setGraphNetworkApi}
          graph={graphData}
          options={{ ...graphOptions, height: `${graphHeight}px` }}
          events={events}
          modalRef={mapContainerRef}
          setSelectedNodes={setSelectedNodes}
          setSelectedEdges={setSelectedEdges}
          enableSelectingFromHighlight={false}
          setSelectingFromHighlight={setSelectingFromHighlight}
        />
      }
      {showTooltip && <Queryable
        query={tooltipQuery}
        joinCondition="or"
        displayImmediately={showTooltip}
        onAdded={() => setShowTooltip(false)}
        flipped={true}
        tooltipStyle={{ top: 0, marginTop: "-5px", marginLeft: "25px" }}
        style={{
          position: "absolute",
          left: `${hoveredElementPosition.x}px`,
          top: `${hoveredElementPosition.y}px`,
          whiteSpace: "nowrap",
          zIndex: 1400
        }}
      >
      </Queryable>}
    </div>
  );
}
