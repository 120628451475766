import React from 'react'

import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  alpha,
  Box,
  Button,
  CircularProgress
} from '@mui/material'

import pluralize from 'pluralize';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

import variables from '../../../variables.module.scss';

interface TableToolbarProps {
  numSelected: number
  searchBy: string
  searchQuery: string
  setSearchQuery: (query: string) => void
  deleteClicked: boolean
  setDeleteClicked: (clicked: boolean) => void
  setDeleteSelectedSignal: (signal: React.SetStateAction<boolean>) => void
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  numSelected,
  searchBy,
  searchQuery,
  setSearchQuery,
  deleteClicked,
  setDeleteClicked,
  setDeleteSelectedSignal
}) => {
  const [deleteSelectedPrompt, showDeleteSelectedPrompt] = React.useState(false)

  const handleDeleteSelectedClick = () => {
    showDeleteSelectedPrompt(true)
  }

  const handleCancelDeleteSelectedClick = () => {
    showDeleteSelectedPrompt(false)
  }

  const handleConfirmDeleteSelectedClick = () => {
    setDeleteClicked(true)
    setDeleteSelectedSignal(prev => !prev)
    showDeleteSelectedPrompt(false)
  }

  const renderToolbarContent = () => {
    if (numSelected === 0) {
      return (
        <TextField
          id="searchEntries"
          label={`Search entries by ${searchBy}`}
          type="search"
          variant="outlined"
          size="medium"
          sx={{ width: "100%", mt: "5px" }}
          InputProps={{
            startAdornment: <SearchIcon color='action' sx={{ mr: "5px" }} />
          }}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      )
    } else if (numSelected > 0) {
      if (deleteSelectedPrompt) {
        return (
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography
              sx={{ flex: '1 1 100%' }}
              color={variables.fontColor}
              variant='subtitle1'
              fontFamily={variables.textFontFamily}
              fontWeight={600}
              component='div'
            >
              Are you sure to delete {numSelected} selected {pluralize('entry', numSelected)}?
            </Typography>
            <Box sx={{ display: 'flex',  gap: '15px' }}>
              <Button sx={{ color: variables.grayColor }} onClick={handleCancelDeleteSelectedClick}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color='error'
                startIcon={<DeleteIcon />}
                onClick={handleConfirmDeleteSelectedClick}
                sx={{ backgroundColor: variables.failureColor }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        )
      }

      if (deleteClicked) {
        return (
          <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'space-between'
          }}>
            <CircularProgress size={18} sx={{ color: variables.blueColor }} />
            <Typography
              sx={{ flex: '1 1 100%' }}
              color={variables.fontColor}
              variant='subtitle1'
              fontFamily={variables.textFontFamily}
              fontWeight={600}
              component='div'
            >
              Deleting {numSelected} selected {pluralize('entry', numSelected)}...
            </Typography>
          </Box>
        )
      }

      return (
        <>
          <Typography
            sx={{ flex: '1 1 100%' }}
            color={variables.fontColor}
            variant='subtitle1'
            fontFamily={variables.textFontFamily}
            fontWeight={600}
            component='div'
          >
            {numSelected} {pluralize('entry', numSelected)} selected
          </Typography>
          <Tooltip title='Delete'>
            <IconButton onClick={handleDeleteSelectedClick}>
              <DeleteIcon htmlColor={variables.failureColor} />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  }

  return (
    <Toolbar
      disableGutters={numSelected === 0}
      sx={{
        ...(numSelected > 0 && {
          pl: { sm: "16px" },
          pr: { xs: 1, sm: "16px" },
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        })
      }}
    >
      {renderToolbarContent()}
    </Toolbar>
  )
}
