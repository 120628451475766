import React from "react";
import { EntryTableSection } from "../EntrySections/EntrySections";
import { KsError } from "../../EntryListItem/Entry"

interface ManifestProps {
  error: KsError;
  color: string;
}

const ErrorSection: React.FC<ManifestProps> = ({ error, color }) => {
  return (
    <EntryTableSection
      title="Error"
      color={color}
      arrayToIterate={[
        {
          name: "Error Type",
          value: error?.type,
          selector: "error.type",
        },
        {
          name: "Message",
          value: error?.msg,
          selector: "error.msg",
        },
      ]}
    />
  );
}

export default ErrorSection
