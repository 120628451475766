import React from 'react'

interface IconProps {
  stroke?: string
}

export const ServiceIcon: React.FC<IconProps> = ({ stroke = 'black' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 24 24'
      fill='transparent'
      stroke={stroke}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <rect x='16' y='16' width='6' height='6' rx='1' />
      <rect x='2' y='16' width='6' height='6' rx='1' />
      <rect x='9' y='2' width='6' height='6' rx='1' />
      <path d='M5 16v-3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3' />
      <path d='M12 12V8' />
    </svg>
  )
}
