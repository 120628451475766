import React from 'react'
import { ConsoleTabProps } from './ConsoleTab'
import styles from './ConsoleTab.module.sass'
import variables from '../../../../variables.module.scss'
import { Box } from '@mui/material'
import { UnreadDot } from '../../../UI/UnreadDot/UnreadDot'

interface DropdownTabProps extends ConsoleTabProps {
  setDropdownOpen: (open: boolean) => void
}

export const DropdownTab: React.FC<DropdownTabProps> = ({
  title,
  index,
  selectedIndex,
  unread = false,
  onSelection,
  setDropdownOpen
}) => {
  return (
    <Box
      className={styles.DropdownTab}
      onClick={() => {
        onSelection(index, true)
        setDropdownOpen(false)
      }}
      sx={{
        backgroundColor: selectedIndex === index ? `${variables.githubEditorBackgroundColor} !important` : null,
        outline: selectedIndex === index ? `1px solid ${variables.slateColor}` : null
      }}
    >
      {unread && <UnreadDot />}
      <Box className={styles.DropdownTabText}>
        {title}
      </Box>
    </Box>
  )
}
