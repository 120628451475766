import React, { useCallback, useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'

import { saveAs } from 'file-saver'

import { useRecoilValue } from 'recoil'
import entriesAtom from '../../../recoil/entries'

import variables from '../../../variables.module.scss'
import styles from '../Filters.module.sass'
import Tooltip from '@mui/material/Tooltip'

export const ExportEntriesToCsvButton = () => {
  const entries = useRecoilValue(entriesAtom)

  const [buttonClicked, setButtonClicked] = useState(false)

  const handleExportToCsv = useCallback(() => {
    setButtonClicked(true)

    const worker = new Worker(
      new URL('./entriesCsvWorker.js', import.meta.url),
      { type: 'module' }
    )

    const entriesLength = [...entries.values()].length

    worker.postMessage({ entries })
    worker.onmessage = (event) => {
      const zipBlob = event.data
      const exportTitle = `kubeshark_entries_exported_${entriesLength}`
      saveAs(zipBlob, `${exportTitle}.zip`)
      worker.terminate()
      setButtonClicked(false)
    }
  }, [entries])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    if (buttonClicked) {
      timeout = setTimeout(handleExportToCsv, 100)
    }

    return () => clearTimeout(timeout)
  }, [buttonClicked])

  return (
    <Tooltip title='Export all entries to CSV' placement='top-start' arrow>
      <Button
        variant='contained'
        color='primary'
        className={styles.smallButton}
        onClick={() => setButtonClicked(true)}
        sx={{
          width: '59px',
          opacity: buttonClicked || [...entries.values()].length === 0 ? 0.75 : 1,
          pointerEvents: buttonClicked || [...entries.values()].length === 0 ? 'none' : 'all'
        }}
      >
        {buttonClicked ? (
          <CircularProgress size={14} color='inherit' />
        ) : (
          <span
            style={{
              padding: '2px',
              borderRadius: '4px',
              fontSize: '10px',
              fontWeight: '600',
              fontFamily: 'Roboto, sans-serif',
              lineHeight: 1,
              letterSpacing: '0.05em',
              border: `1px solid ${variables.mainBackgroundColor}`,
            }}
          >
            CSV
          </span>
        )}
      </Button>
    </Tooltip>
  )
}
