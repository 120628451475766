import React from 'react'

interface IconProps {
  stroke?: string
}

export const NamespaceIcon: React.FC<IconProps> = ({ stroke }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='1'
        width='18'
        height='18'
        rx='1'
        stroke={stroke}
        strokeWidth='2'
        strokeDasharray='3 3'
      />
    </svg>
  )
}
