export interface ColorCluster {
  name: string
  colors: string[]
}

export const nodeBackgrounds: ColorCluster[] = [
  {
    name: 'lavender',
    colors: [
      '#EDE5FD', '#E4D8FC', '#D2BEFA',
      '#C0A3F8', '#AE89F6', '#8D5AF2',
      '#7637F0', '#5010CE', '#350A89'
    ]
  },
  {
    name: 'sky blue',
    colors: [
      '#CED8F2', '#ADBEEA', '#8CA4E1',
      '#6B8AD8', '#5B7DD4', '#436ACE',
      '#2C4FA8', '#1F3878', '#132248'
    ]
  },
  {
    name: 'royal blue',
    colors: [
      '#ECF0FF', '#D9E0FF', '#C6D1FF',
      '#B3C1FF', '#A0B2FF', '#4D6FFF',
      '#002FF9', '#001FA6', '#001053'
    ]
  },
  {
    name: 'aqua',
    colors: [
      '#E2F9FC', '#C6F3F9', '#A9EDF7',
      '#8DE7F4', '#70E1F1', '#30D4EB',
      '#13ABC1', '#0D7281', '#063940'
    ]
  },
  {
    name: 'mint green',
    colors: [
      '#E2FEF0', '#C4FDE2', '#A7FDD3',
      '#89FCC5', '#6CFBB6', '#26F993',
      '#06D26F', '#048C4A', '#024625'
    ]
  },
  {
    name: 'lime',
    colors: [
      '#F4FDE2', '#EAFBC4', '#DFFAA7',
      '#D5F889', '#CAF66C', '#B2F229',
      '#8CC80C', '#5D8508', '#2F4304'
    ]
  },
  {
    name: 'spring green',
    colors: [
      '#EDF6DE', '#DBEEBD', '#CAE59D',
      '#B8DD7C', '#A6D45B', '#8AC032',
      '#689026', '#456019', '#23300D'
    ]
  },
  {
    name: 'forest green',
    colors: [
      '#ECF2EC', '#D9E5D9', '#C6D7C6',
      '#B3CAB3', '#A0BDA0', '#77A077',
      '#567C56', '#395239', '#1D291D'
    ]
  },
  {
    name: 'teal',
    colors: [
      '#DEEDEC', '#BDDBD9', '#9DCAC6',
      '#7CB8B3', '#5BA6A0', '#488580',
      '#366460', '#244340', '#122120'
    ]
  },
  {
    name: 'cerulean',
    colors: [
      '#DEECF0', '#BDD9E0', '#9DC6D1',
      '#7CB3C1', '#5BA0B2', '#458292',
      '#34626E', '#224149', '#112125'
    ]
  },
  {
    name: 'golden yellow',
    colors: [
      '#FDF5DF', '#FCEBBF', '#FAE1A0',
      '#F9D780', '#F7CD60', '#F4B81F',
      '#C4900A', '#836007', '#413003'
    ]
  },
  {
    name: 'sand',
    colors: [
      '#F2ECDD', '#E5D9BC', '#D7C69A',
      '#CAB379', '#BDA057', '#9F833E',
      '#77622F', '#4F421F', '#282110'
    ]
  },
  {
    name: 'stone gray',
    colors: [
      '#EBE8E8', '#D7D2D2', '#C4BBBB',
      '#B0A5A5', '#9C8E8E', '#7F6F6F',
      '#5F5353', '#403838', '#201C1C'
    ]
  },
  {
    name: 'rosy red',
    colors: [
      '#F6E7E7', '#EDCFCF', '#E5B8B8',
      '#DCA0A0', '#D38888', '#C05555',
      '#983838', '#652525', '#331313'
    ]
  },
  {
    name: 'terracotta',
    colors: [
      '#F6E5DE', '#EECBBD', '#E5B19D',
      '#DD977C', '#D47D5B', '#C05A32',
      '#904426', '#602D19', '#30170D'
    ]
  },
  {
    name: 'peach',
    colors: [
      '#FFECE5', '#FFD9CB', '#FFC6B1',
      '#FFB397', '#FFA07D', '#FF6831',
      '#E43D00', '#982900', '#4C1400'
    ]
  },
  {
    name: 'fuchsia',
    colors: [
      '#FFECF0', '#FFD9E0', '#FFC6D1',
      '#FFB3C1', '#FFA0B2', '#FF4D6F',
      '#F9002F', '#A6001F', '#530010'
    ]
  },
  {
    name: 'pink magenta',
    colors: [
      '#F6DEED', '#EEBDDB', '#E59DCA',
      '#DD7CB8', '#D45BA6', '#C0328A',
      '#902668', '#601945', '#300D23'
    ]
  },
  {
    name: 'violet',
    colors: [
      '#F0DEEC', '#E0BDD9', '#D19DC6',
      '#C17CB3', '#B25BA0', '#924582',
      '#6E3462', '#492241', '#251121'
    ]
  },
  {
    name: 'indigo',
    colors: [
      '#DFDBED', '#B0A5D1', '#A094C8',
      '#8170B6', '#64519E', '#50417E',
      '#3C315F', '#32294F', '#141020'
    ]
  },
];

