import {
  Modal,
  Box,
  IconButton,
  Typography,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Utils } from '../../../helpers/Utils';
import Moment from 'moment-timezone';

import styles from './TimezoneModal.module.sass';
import variables from '../../../variables.module.scss'

import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChangeCircleRoundedIcon from '@mui/icons-material/ChangeCircleRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import timezoneModalOpenAtom from '../../../recoil/timezoneModalOpen/atom';
import timezoneAtom from '../../../recoil/timezone/atom';
import { LocalStorageKey } from '../../../consts';
import { appTimezone } from '../../../types/global';
import { InlineCode } from '../../UI/InlineCode/InlineCode';
import { ResetToAppTimezoneSection } from './ResetToAppTimezoneSection';

interface TimezoneOption {
  value: string
  label: string
}

const composeTimezoneOption = (timezone: string): TimezoneOption => {
  return {
    label: `(UTC${Utils.TimezoneMoment(timezone).format('Z')}) ${timezone}`,
    value: timezone
  }
}


export const TimezoneModal: React.FC = () => {
  const [timezoneModalOpen, setTimezoneModalOpen] = useRecoilState(timezoneModalOpenAtom)

  const [currentTimezone, setCurrentTimezone] = useRecoilState(timezoneAtom)

  const resolveCurrentTimezoneOption = (onlyLocal = false): TimezoneOption => {
    let resolvedTimezone = ''

    if (!onlyLocal && Moment.tz.names().includes(currentTimezone)) {
      resolvedTimezone = currentTimezone
    } else if (!onlyLocal && Moment.tz.names().includes(appTimezone())) {
      resolvedTimezone = appTimezone()
    }

    if (resolvedTimezone.trim() === '') {
      resolvedTimezone = Moment.tz.guess(true)
    }

    return composeTimezoneOption(resolvedTimezone)
  }

  const handleCloseTimezoneModal = () => {
    setTimezoneModalOpen(false)
    setTimezoneOption(resolveCurrentTimezoneOption())
  }

  const [timezoneOptions, setTimezoneOptions] = useState<TimezoneOption[]>([])

  useEffect(() => {
    setTimezoneOptions(Moment.tz.names().map((timezone: string): TimezoneOption => {
      return {value: timezone, label: `(UTC${Utils.TimezoneMoment(timezone).format('Z')}) ${timezone}`}
    }))

  }, [])

  useLayoutEffect(() => {
    setTimezoneOption(resolveCurrentTimezoneOption())
  }, [])

  const [timezoneOption, setTimezoneOption] = useState<TimezoneOption>(resolveCurrentTimezoneOption())

  const handleSetTimezoneOption = (event, option: TimezoneOption) => setTimezoneOption(option)

  const handleSetTimezoneLocalOption = () => {
    setTimezoneOption(resolveCurrentTimezoneOption(true))
  }
  const handleSetTimezoneUtcOption = () => {
    setTimezoneOption(composeTimezoneOption('UTC'))
  }

  const handleSetTimezone = () => {
    const newTimezone = timezoneOption?.value ?? ''

    setCurrentTimezone(newTimezone)
    localStorage.setItem(LocalStorageKey.Timezone, newTimezone)
    setTimezoneModalOpen(false)
  }

  const handleResetToAppTimezone = () => {
    setCurrentTimezone(appTimezone())
    setTimezoneOption(composeTimezoneOption(appTimezone()))
    localStorage.removeItem(LocalStorageKey.Timezone)
    setTimezoneModalOpen(false)
  }

  if (!timezoneModalOpen) {
    return null
  }

  const shouldShowResetToAppTzSection = (): boolean => {
    return Moment.tz.names().includes(appTimezone()) && currentTimezone !== appTimezone()
  }

  return (
    <Modal open={timezoneModalOpen} onClose={handleCloseTimezoneModal} disableAutoFocus>
      <Box className={styles.TimezoneModal}>
        <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <AccessTimeFilledRoundedIcon
              htmlColor={variables.lightBlueColor}
              sx={{ fontSize: '42px' }}
            />
            <ChangeCircleRoundedIcon
              fontSize='medium'
              htmlColor={variables.blueColor}
              sx={{ position: 'absolute', bottom: '-3px', right: '-10px' }}
            />
          </Box>
          <IconButton onClick={handleCloseTimezoneModal}>
            <CloseRoundedIcon htmlColor={variables.grayColor} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Typography
            variant='h3'
            fontFamily={variables.textFontFamily}
            fontWeight={700}
            color={variables.fontColor}
          >
            Change timezone
          </Typography>
        </Box>
        <ResetToAppTimezoneSection show={shouldShowResetToAppTzSection()} handleReset={handleResetToAppTimezone} />
        <Typography
          variant='body1'
          fontFamily={variables.textFontFamily}
          color={variables.fontColor}
        >
          New timezone applies to all displayed timestamps.<br/><br/>
          <span style={{ fontWeight: 600 }}>This is a user-level change.</span><br/>
          To change UI timezone for all users, use helm <InlineCode>timezone</InlineCode> field.
        </Typography>
        <Box display='flex' alignItems='center' gap='10px' width='100%'>
          <Autocomplete
            fullWidth
            disablePortal
            disableClearable
            selectOnFocus
            value={timezoneOption}
            onChange={handleSetTimezoneOption}
            options={timezoneOptions}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => <TextField {...params} label='Timezone' />}
          />
          {Utils.TimezoneMoment(timezoneOption.value).format('Z') !== '+00:00' && (
            <Button
              color='primary'
              variant='outlined'
              size='large'
              sx={{
                height: '56px',
                pl: '20px',
                pr: '20px'
              }}
              onClick={handleSetTimezoneUtcOption}
            >
              <Typography
                variant='body1'
                fontSize='14px'
                fontFamily='Roboto, sans-serif'
                fontWeight={500}
                whiteSpace='nowrap'
                textTransform='initial'
                lineHeight={1}
                color={variables.blueColor}
              >
                To UTC
              </Typography>
            </Button>
          )}
          {timezoneOption.value !== resolveCurrentTimezoneOption(true).value && (
            <Button
              color='primary'
              variant='outlined'
              size='large'
              sx={{
                height: '56px',
                pl: '20px',
                pr: '20px'
              }}
              onClick={handleSetTimezoneLocalOption}
            >
              <Typography
                variant='body1'
                fontSize='14px'
                fontFamily='Roboto, sans-serif'
                fontWeight={500}
                whiteSpace='nowrap'
                textTransform='initial'
                lineHeight={1}
                color={variables.blueColor}
              >
                To Local
              </Typography>
            </Button>
          )}
        </Box>
        <Box display='flex' alignItems='center' gap='10px'>
          <Button
            color='success'
            variant='contained'
            size='large'
            startIcon={<CheckRoundedIcon sx={{ marginRight: '5px' }} />}
            sx={{
              backgroundColor: variables.successColor,
              pl: '15px',
              pr: '15px'
            }}
            onClick={handleSetTimezone}
          >
            <Typography
              variant='body1'
              fontFamily={variables.textFontFamily}
              fontWeight={600}
              color='#FFFFFF'
            >
              Apply
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
