import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Box, Tabs, Tab } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { StatusBar } from '../UI/StatusBar/StatusBar'
import { DisableTrafficCapturingButton } from
  './DisableTrafficCapturingButton/DisableTrafficCapturingButton'
import { ApiCallsView } from "./ApiCallsView/ApiCallsView"
import { ServiceMapView } from "./ServiceMapView/ServiceMapView"

import { useAuth } from "../UI/Auth/Auth"
import useStreamingList from './useStreamingList'
import useStreamingWebSocket, { THROTTLE_WS_STATE_CHANGE_MS } from '../../hooks/useStreamingWebSocket'

import { resetHeapCache } from '../../helpers/cacher/cacher'

import {
  appAuthEnabled,
  appStopTrafficCapturingDisabled, checkScriptingDisabled
} from '../../types/global'

import queryAtom from "../../recoil/query"
import packetCapturingStoppedAtom from '../../recoil/packetCapturingStopped'
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from
  "../../recoil/trafficPlayback/atom"
import fullscreenViewAtom from "../../recoil/fullscreenView/atom"

import TrafficViewerStyles from "./TrafficViewer.module.sass"

import { StreamingPauseIcon } from '../UI/Icons/StreamingPauseIcon'
import { StreamingPlayIcon } from '../UI/Icons/StreamingPlayIcon'

import queryIsReadyAtom from '../../recoil/queryIsReady'
import { ScriptingView } from './ScriptingView/ScriptingView'
import { ConsoleView } from './ConsoleView/ConsoleView'
import trafficViewTabAtom from '../../recoil/trafficViewTab'
import { LoggedInAs } from '../UI/LoggedInAs/LoggedInAs'
import Tooltip from '@mui/material/Tooltip'
import { trafficViewTabs } from '../../recoil/trafficViewTab/atom'
import { Filters } from '../Filters/Filters'
import queryBuildAtom from '../../recoil/queryBuild'
import { TrafficStatsFooter } from './TrafficStatsFooter/TrafficStatsFooter'
import scriptAllWsLogsAtom from '../../recoil/scriptAllWsLogs'
import { ScriptLogs } from '../../hooks/useScriptLogTracker'
import { UnreadDot } from '../UI/UnreadDot/UnreadDot'

import variables from '../../variables.module.scss'
import entryDetailsOpenAtom from '../../recoil/entryDetailsOpen'

export const TrafficViewer: React.FC = () => {
  const query = useRecoilValue(queryAtom)
  const queryIsReady = useRecoilValue(queryIsReadyAtom)
  const fullscreenView = useRecoilValue(fullscreenViewAtom)
  const trafficPlayback = useRecoilValue(trafficPlaybackAtom)
  const capturingStopped = useRecoilValue(packetCapturingStoppedAtom)
  const scriptAllWsLogs = useRecoilValue(scriptAllWsLogsAtom)

  const setQueryBuild = useSetRecoilState(queryBuildAtom)
  const setEntryDetailsOpen = useSetRecoilState(entryDetailsOpenAtom)

  const [currentTab, setCurrentTab] = useRecoilState(trafficViewTabAtom)
  const [playbackClicked, setPlaybackClicked] = useState(false)
  const [unreadConsoleLogsFound, setUnreadConsoleLogsFound] = useState(false)

  const streamingListRef = useRef(null)

  const { shouldAuthenticate } = useAuth()
  const { setToBottom, connectionTitle, connectionIndicator } = useStreamingList()
  const { open, send, close, toggle, readyState } = useStreamingWebSocket()

  useEffect(() => {
    if (readyState !== WebSocket.OPEN || !queryIsReady) {
      setToBottom(false)
      return
    }

    setToBottom(true)
    send(query)
  }, [readyState, queryIsReady, query])

  useEffect(() => {
    if (trafficPlayback !== TRAFFIC_PLAYBACK_OK || shouldAuthenticate) {
      close()
      return
    }

    if (
      !appStopTrafficCapturingDisabled() &&
      (capturingStopped === null || capturingStopped)
    ) {
      close()
      return
    }

    open()

    return () => resetHeapCache()
  }, [trafficPlayback, shouldAuthenticate, capturingStopped])

  const toggleConnection = useCallback(() => {
    if (
      !appStopTrafficCapturingDisabled() &&
      (capturingStopped === null || capturingStopped)
    ) {
      return
    }

    toggle()
  }, [capturingStopped])

  useEffect(() => {
    if (playbackClicked) {
      setTimeout(() => setPlaybackClicked(false), THROTTLE_WS_STATE_CHANGE_MS)
    }
  }, [playbackClicked])

  useEffect(() => {
    const unreadLogsFound = Object.values(scriptAllWsLogs).findIndex((scriptLog: ScriptLogs) => {
      return scriptLog?.unread
    })

    setUnreadConsoleLogsFound(unreadLogsFound !== -1)
  }, [scriptAllWsLogs])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    const isScriptingRelatedTab = [trafficViewTabs.SCRIPTING, trafficViewTabs.CONSOLE].includes(newValue)
    if (isScriptingRelatedTab && checkScriptingDisabled()) {
      return
    }

    setEntryDetailsOpen(true)

    setCurrentTab(newValue);
  };

  const composeTabClassNames = useCallback((index: number) => {
    let selectedTabClass = ''
    if (currentTab === index) {
      selectedTabClass = TrafficViewerStyles.TrafficPageNavTabSelected
    }

    return `${TrafficViewerStyles.TrafficPageNavTab} ${selectedTabClass}`
  }, [currentTab])

  return (
    <div
      className={TrafficViewerStyles.TrafficPage}
      style={{ height: fullscreenView ? '100%' : 'calc(100% - 70px)' }}
    >
      {!fullscreenView && (
        <Box>
          <StatusBar />
          <div className={TrafficViewerStyles.TrafficPageHeader}>
            <div className={TrafficViewerStyles.TrafficPageStreamStatus}>
              <Box
                className={TrafficViewerStyles.playPauseIcon}
                boxSizing='border-box'
                onClick={() => {
                  if (playbackClicked) {
                    return
                  }

                  setPlaybackClicked(true)
                  toggleConnection()
                }}
                style={{
                  transition: 'opacity 0.2s',
                  opacity: playbackClicked ? 0.5 : 1,
                  pointerEvents: playbackClicked ? 'none' : 'all',
                }}
              >
                {!playbackClicked && readyState === WebSocket.OPEN ?
                  <StreamingPauseIcon /> :
                  <StreamingPlayIcon />
                }
              </Box>
              <div
                className={TrafficViewerStyles.connectionText}
                style={{
                  opacity: playbackClicked ? 0.8 : 1,
                }}
              >
                {playbackClicked ? 'changing...' : connectionTitle}
                {!playbackClicked && connectionIndicator}
              </div>
            </div>
            <Box boxSizing='border-box' position='absolute' left='250px'>
              <DisableTrafficCapturingButton mode='link' size='small' />
            </Box>
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              indicatorColor='secondary'
              aria-label='Tabs navigation'
              sx={{
                minHeight: '45px',
                height: '45px',
                '.MuiTabs-fixed': {
                  position: 'unset'
                }
              }}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab
                label='API Calls'
                className={composeTabClassNames(0)}
                hidden={currentTab !== 0}
              />
              <Tab
                label='Service Map'
                className={composeTabClassNames(1)}
                hidden={currentTab !== 1}
              />
              <Tooltip
                title={checkScriptingDisabled() ? 'Scripting is disabled' : ''}
                arrow
              >
                <Tab
                  value={2}
                  label='Scripting'
                  className={composeTabClassNames(2)}
                  hidden={currentTab !== 2}
                  sx={{
                    position: 'absolute',
                    right: '100px',
                    opacity: checkScriptingDisabled() ? 0.5 : 1,
                    cursor: checkScriptingDisabled() ? 'not-allowed' : 'pointer',
                  }}
                />
              </Tooltip>
              <Tooltip
                title={checkScriptingDisabled() ? 'Console is disabled' : ''}
                arrow
              >
                <Tab
                  value={3}
                  label={
                    <Box
                      boxSizing='border-box'
                      position='relative'
                      display='flex'
                      alignItems='center'
                      gap='5px'
                    >
                      <span>Console</span>
                      {unreadConsoleLogsFound && (
                        <UnreadDot
                          color={variables.darkestWarningColor}
                          position='absolute'
                          top={-8}
                          right={-9}
                        />
                      )}
                    </Box>
                  }
                  className={composeTabClassNames(3)}
                  hidden={currentTab !== 3}
                  sx={{
                    position: 'absolute',
                    right: '0px',
                    opacity: checkScriptingDisabled() ? 0.5 : 1,
                    cursor: checkScriptingDisabled() ? 'not-allowed' : 'pointer',
                  }}
                />
              </Tooltip>

            </Tabs>
            {appAuthEnabled() && (
              <Box
                boxSizing='border-box'
                position='absolute'
                right='200px'
              >
                <LoggedInAs />
              </Box>
            )}
          </div>
        </Box>
      )}
      {[trafficViewTabs.API_CALLS, trafficViewTabs.SERVICE_MAP].includes(currentTab) && (
        <Filters
          onQueryChange={(q) => {
            setQueryBuild(q?.trim())
          }}
        />
      )}
      {currentTab === 0 && <ApiCallsView scrollableRef={streamingListRef} />}
      {currentTab === 1 && <ServiceMapView />}
      {currentTab === 2 && <ScriptingView />}
      {currentTab === 3 && <ConsoleView />}
      <TrafficStatsFooter />
    </div>
  )
};
