import React from 'react'
import { Box } from '@mui/material'

import variables from '../../../variables.module.scss'
import styles from './UnreadDot.module.sass'

interface UnreadDotProps {
  color?: string
  position?: 'absolute' | 'static'
  top?: number
  right?: number
}

export const UnreadDot: React.FC<UnreadDotProps> = ({
  color = variables.highContrastWarningColor,
  position = 'absolute',
  top = '15px',
  right = '15px'
}) => {
  return (
    <Box
      boxSizing='border-box'
      position={position}
      top={top}
      right={right}
      zIndex={1}
    >
      <div className={styles.AnimatedUnreadDot}>
        <div
          className={styles.CenterDot}
          style={{
            backgroundColor: color
          }}
        ></div>
        <div
          className={styles.PulsatingCircle}
          style={{
            border: `1px solid ${color}`
          }}
        ></div>
      </div>
    </Box>
  )
}
