import React from 'react'
import variables from '../../../../variables.module.scss'
import { CircularProgress, Fab } from '@mui/material'
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded'

interface DeactivateScriptButtonProps {
  darkModeEnabled: boolean
  inProgress: boolean
  onClick: () => void
}

export const DeactivateScriptButton: React.FC<DeactivateScriptButtonProps> = ({ darkModeEnabled, inProgress, onClick }) => {
  return (
    <Fab
      variant='extended'
      color='warning'
      onClick={onClick}
      sx={{
        backgroundColor: darkModeEnabled
          ? variables.lightOrangeColor
          : variables.orangeColor,
        opacity: inProgress ? 0.8 : 1,
        pointerEvents: inProgress ? 'none' : 'all',
      }}
    >
      {inProgress && <CircularProgress size={14} color='inherit' sx={{ mr: '10px' }} />}
      {!inProgress && <StopCircleRoundedIcon
        htmlColor={
          darkModeEnabled
            ? variables.lighterOrangeColor
            : variables.mainBackgroundColor
        }
        sx={{ mr: '10px' }}
      />}
      {inProgress ? 'Deactivating now...' : 'Deactivate'}
    </Fab>
  )
}
