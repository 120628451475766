import { atom } from "recoil";
import { appTimezone } from "../../types/global";
import { LocalStorageKey } from "../../consts";

import Moment from 'moment-timezone';

const resolveDefaultTimezone = () => {
  const localStorageTz = localStorage.getItem(LocalStorageKey.Timezone)

  if (localStorageTz !== null && Moment.tz.names().includes(localStorageTz)) {
    return localStorageTz
  }

  if (appTimezone() !== '' && Moment.tz.names().includes(appTimezone())) {
    return appTimezone()
  }

  return Moment.tz.guess(true)
}

const timezoneAtom = atom({
  key: "timezoneAtom",
  default: resolveDefaultTimezone()
});

export default timezoneAtom;
