import React from 'react'

import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded'
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import Filter1RoundedIcon from '@mui/icons-material/Filter1Rounded'
import ConnectWithoutContactRoundedIcon from '@mui/icons-material/ConnectWithoutContactRounded';
import WaterfallChartRoundedIcon from '@mui/icons-material/WaterfallChartRounded';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PublicIcon from '@mui/icons-material/Public';
import InsightsIcon from '@mui/icons-material/Insights';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

const SCRIPT_EMPTY = `// Nothing here, it's time to free your imagination!

// 🧑‍💻 See how Scripting benefits your experience in Kubeshark ⬇️:
// https://docs.kubeshark.co/en/automation_scripting
`;
const SCRIPT_BOOTSTRAP = `// Define your global variables here
// var example = 0;

// Hooks operate on worker scope and are executed on each Kubernetes node
/**
 * L7 Hook: This function is invoked for every emitted item, representing a reassembled message. 
 * It operates continuously, regardless of the state of the dashboard, whether open or closed.
 * 
 * This hook processes the entire metadata object provided as input. It enables inline processing 
 * of metadata, allowing for quick actions such as calculations or assignments based on the data. 
 * Complex or time-consuming operations should not be included in this function, as it executes 
 * inline with every API call. For such tasks, offload the processing to asynchronous jobs.
 *
 * @param {Object} data - The metadata object containing detailed information about the reassembled message. 
 * The exact structure can be referenced here: 
 * https://github.com/kubeshark/api/blob/856984a4d0fcccd0317da948c9e3059fdba59fcd/api.go#L330
 * @returns {void} - This function does not return any value.
 * 
 * @example
 * Key metadata fields available for inspection:
 * ==================================================
 * - data.dst.ip
 * - data.dst.name
 * - data.dst.namespace
 * - data.src.ip
 * - data.src.name
 * - data.src.namespace
 * - data.protocol.name
 * - data.request.method
 * - data.request.headers
 * - data.response.headers
 * - data.response.bodySize
 * - data.response.status
 * - data.response.statusText
 * - data.size
 * - data.responseSize
 * - data.requestSize
 * - data.elapsedTime
 * - data.timestamp
 * - data.stream
 * - data.startTime
 * - data.src.processName
 * - data.src.processId
 * - data.dst.processName
 * - data.dst.processId
 * 
 * @example
 * // Example 1: Capturing specific request path
 * function onItemCaptured(data) {
 *   console.log(data.Request.Path);
 *   if (data.Request.Path === "/health") {
 *     const response = vendor.webhook(
 *       "POST",
 *       env.WEBHOOK_URL,
 *       JSON.stringify(data),
 *       { "content-type": "application/json" }
 *     );
 *   }
 * }
 * 
 * @example
 * // Example 2: Logging the entire metadata object
 * function onItemCaptured(data) {
 *   console.log(JSON.stringify(data));
 * }
 */

function onItemCaptured(data) {
    // Implement your hook logic here
}
  
function onPacketCaptured(info) {
  // Triggered when a packet is captured from the network
  // Your code goes here
  // console.log(JSON.stringify(info));
}

function onItemQueried(data, filter) {
  // Triggered when a request-response pair is queried through front-end
  // This hook can change the data before it is presented to the user
  // Your code goes here
  // console.log(JSON.stringify(data), filter);
  // return data; // this is mandatory. data object can be modified before returning (e.g. remove sensitive data)
}

// Schedule your jobs here
// jobs.schedule("example-job", "*/5 * * * * *", exampleJob)
`;
const SCRIPT_WEBHOOK = `// Call a Webhook For Each Health Check

function onItemCaptured(data) {
  console.log(data.Request.Path);
  if (data.Request.Path === "/health")
    response = vendor.webhook(
      "POST",
      env.WEBHOOK_URL,
      JSON.stringify(data),
      {
        "content-type": "application/json"
      }
    );
}
`;

const SCRIPT_SLACK = `// Report To a Slack Channel If HTTP Status Code is 500 Example

function onItemCaptured(data) {
  // Check if it's an HTTP request and the response status is 500
  if (data.Protocol.name === "http" && data.Response.Status === 500) {
    var files = {};

    // Get the path of the PCAP file that this stream belongs to
    var pcapPath = pcap.path(data.Stream);
    files[data.Stream + ".pcap"] = pcapPath;

    // Dump the \`data\` argument into a temporary JSON file
    var dataPath = file.temp("data", "", "json");
    file.write(dataPath, JSON.stringify(data, null, 2));
    files["data.json"] = dataPath;

    // Send a detailed Slack message with 2 attached files
    vendor.slackBot(
      env.SLACK_AUTH_TOKEN,
      env.SLACK_CHANNEL_ID,
      "Server-side Error in Kubernetes Cluster",                                    // Pretext
      "An HTTP request resulted with " + data.Response.Status + " status code:",    // Text
      "#ff0000",                                                                    // Color
      {
        "Service": data.Destination.Name,
        "Namespace": data.Namespace,
        "Node": data.Node.Name,
        "HTTP method": data.Request.Method,
        "HTTP path": data.Request.Path
      },
      files
    );

    // Delete the temporary file
    file.delete(dataPath);
  }
}
`;

const SCRIPT_LOG_TOTAL_CAPTURED_PACKET_KB_PER_MIN = `// Log Total Captured Packet and KB Every Minute

var packetCount = 0;
var totalKB = 0;

function onPacketCaptured(info) {
  packetCount++;
  totalKB += info.length / 1000;
}

function logPacketCountTotalBytes() {
  console.log("Captured packet count per minute:", packetCount);
  packetCount = 0;
  console.log("Total KB captured per minute:", totalKB);
  totalKB = 0;
}

jobs.schedule("log-packet-count-total-bytes", "0 */1 * * * *", logPacketCountTotalBytes);
`;

const SCRIPT_MONITORING_PASS_HTTP = `// Monitoring: Fail HTTP Status Code is 500, Pass Anything Else

function onItemQueried(data, filter) {
  if (data.Protocol.name === "http" && data.Response.Status === 500)
    return test.fail(data);
  else
    return test.pass(data);
}
`;

const SCRIPT_PRINT_ENV = `// Print Environment Variables

console.log(JSON.stringify(env));

/*
 * The script uses the helper 'console.log' to print a stringified version of the 'env' object to the console.
 * The 'env' object contains the environment variables that you have set in the script configuration.
 * 
 * For example:
 * scripting:
 *  env:
 *   MY_ENV_VAR: "my-value"
 * 
 * Read more about environment variables in the documentation: https://docs.kubeshark.com/docs/scripting/environment-variables
 * Read more about the 'console.log' helper in the documentation: https://docs.kubeshark.com/docs/scripting/helpers#console-log
 */
`

const SCRIPT_INFLUXDB = `// Aggregate the HTTP Status Codes and Push Them to InfluxDB Every Minute

var statusCodes = {};

function onItemCaptured(data) {
  if (data.Protocol.Name !== "http") return;

  if (statusCodes.hasOwnProperty(data.Response.Status)) {
    statusCodes[data.Response.Status]++;
  } else {
    statusCodes[data.Response.Status] = 1;
  }
}

function pushStatusCodesToInfluxDB() {
  console.log("Status Codes:", JSON.stringify(statusCodes));

  vendor.influxdb(
    env.INFLUXDB_URL,
    env.INFLUXDB_TOKEN,
    env.INFLUXDB_ORGANIZATION,
    env.INFLUXDB_BUCKET,
    "Status Codes",               // Measurement
    statusCodes                   // Payload
  );

  statusCodes = {};
}

jobs.schedule("push-status-codes-to-influxdb", "0 */1 * * * *", pushStatusCodesToInfluxDB);
`

const SCRIPT_ELASTIC = `// Aggregate the HTTP Status Codes and Push Them to Elastic Cloud Every Minute

var statusCodes = {};

function onItemCaptured(data) {
  if (data.Protocol.Name !== "http") return;

  if (statusCodes.hasOwnProperty(data.Response.Status)) {
    statusCodes[data.Response.Status]++;
  } else {
    statusCodes[data.Response.Status] = 1;
  }
}

function pushStatusCodesToElasticsearch() {
  console.log("Status Codes:", JSON.stringify(statusCodes));

  vendor.elastic(
    "",                     // URL is ignored for Elastic Cloud
    env.ELASTIC_INDEX,
    statusCodes,            // Payload
    "",                     // Username is ignored for Elastic Cloud
    "",                     // Password is ignored for Elastic Cloud
    env.ELASTIC_CLOUD_ID,
    env.ELASTIC_API_KEY
  );

  statusCodes = {};
}

jobs.schedule("push-status-codes-to-elastic", "0 */1 * * * *", pushStatusCodesToElasticsearch);
`

const SCRIPT_S3 = `// Upload a file (e.g. PCAP) to an AWS S3 Bucket 
function jobS3(){
  if (env.AWS_ACCESS_KEY_ID && env.AWS_SECRET_ACCESS_KEY)
    vendor.s3.put(
      env.S3_BUCKET , name + "_" + tarFile, env.AWS_REGION, 
      env.AWS_ACCESS_KEY_ID, env.AWS_SECRET_ACCESS_KEY    
    ); 
  else
    vendor.s3.put( env.S3_BUCKET , name + "_" + tarFile, env.AWS_REGION ); 
}
`;

const SCRIPT_S3_SNAPSHOT = `// Upload a PCAP Snapshot to an AWS S3 Bucket If HTTP Status Code is 500

function onItemCaptured(data) {
  if (data.Protocol.Name === "http" && data.Response.Status === 500) {
    // Create a temporary directory
    var dir = file.mkdirTemp("snapshot");

    // Create the PCAP snapshot
    var snapshot = pcap.snapshot();

    // Move the snapshot into the temporary directory
    file.move(snapshot, dir);

    // Dump the name resolution history into a file
    var nameResolutionHistory = pcap.nameResolutionHistory();
    file.write(
      dir + "/name_resolution_history.json",
      JSON.stringify(nameResolutionHistory)
    );

    // Create an archive from the directory
    var tarFile = file.tar(dir);

    // Upload TAR file to S3 bucket
    var location = vendor.s3.put(
      env.S3_BUCKET,
      tarFile,
      env.AWS_REGION,             // optional, default: us-east-2 if can't load from default AWS config
      env.AWS_ACCESS_KEY_ID,      // optional, default: loaded from default AWS config
      env.AWS_SECRET_ACCESS_KEY   // optional, default: loaded from default AWS config
    );
    console.log("Uploaded PCAP snapshot to S3:", tarFile, "URL:", location);

    /*
    The TAR file kubeshark_<TIMESTAMP>.tar.gz can now be downloaded from the Amazon S3 bucket.
    Use \`kubeshark tap --pcap <TAR_FILE_PATH>\` command to capture from the PCAP snapshot (.tar.gz file)
    */

    // Clean up the temporary files and directories
    file.delete(dir);
    file.delete(tarFile);
  }
}
`;

const SCRIPT_ERROR_HANDLING = `// Error Handling

/*
 * The script uses the 'try-catch' block to handle errors that may occur during the execution of the script.
 * The 'try' block contains the code that may throw an error, and the 'catch' block contains the code that handles the error.
 * 
 * For example:
 * The script tries to match an invalid KFL query 'htt ??? a : p' with the data object.
 * The invalid query throws an error, and the 'catch' block prints the error message to the console.
 */

function onItemCaptured(data) {
  try {
    // Invalid KFL query throws an error
    if (kfl.match("htt ??? a : p", data)) {
      console.log(true);
    } else {
      console.log(false);
    }
  } catch (error) {
    // Should print 'Caught an error! Error: 1:5: unexpected token "?"'
    console.log("Caught an error!", error);
  }
}

`

const SCRIPT_CHATGPT = `// Use ChatGPT to Detect Unprocessable HTTP Requests

function onItemCaptured(data) {
  if (data.Protocol.Name == "http") {
    // Extract only the fields that we are interested in to not confuse ChatGPT
    var fieldsOfInterest = { request: data.Request, response: data.Response }

    var payload = JSON.stringify(fieldsOfInterest);
    var prompt = "Is the HTTP request unprocessable by the HTTP server in this HTTP request-response pair? " + payload;

    var response = chatgpt.prompt(
      env.OPENAI_API_KEY,
      prompt.slice(0, 3000)   // Limit the prompt size to not exceed 4097 tokens limit
    );
    // console.log("Actual HTTP status:", data.Response.Status, "ChatGPT:", response);

    var score = chatgpt.sentiment(response);
    if (score.pos > 0) {
      console.log("ALERT! ChatGPT is detected an unprocessable request:", response, "Payload:", payload);
    }
  }
}
`

const SCRIPT_CUSTOM_PROMETHEUS_METRIC = `// Export custom metric to Prometheus

/*
  * The script uses the 'prometheus.metric' helper to export a custom metric to Prometheus.
  * The 'prometheus.metric' helper takes the following arguments:
  * - metricName: The name of the metric.
  * - metricDescription: The description of the metric.
  * - metricType: The type of the metric (1 for counter, 2 for gauge).
  * - metricValue: The value of the metric.
 */

var counter = 0;

function onItemCaptured(data) {
  // Check if it's a DNS request
  if (data.Protocol.Name === "dns") {
    counter++;
  }
}

// Report to Prometheus every 15 seconds
jobs.schedule("example-job", "*/15 * * * * *", reportToProm)

function reportToProm (){
  prometheus.metric("dns_counter", "Total number of DNS requests", 1, counter);
}
`

const SCRIPT_EXTERNAL_CONNECTION = `// Report external connections

/**
 * Monitors and logs external connections from the cluster or namespace.
 * 
 * This script is good to detect and log pods with external connections from the cluster or namespace.
 * This script can be extended to detect processes at the operating system level that were not expected to have external connections.
 * 
 * As an example, consider a hacker SSHing into a pod, and using curl to download or upload encrypted
 * payload to an external server. If the pod is allowed to have an external connection, 
 * the process is likely to be successful. This script can be extended to help detect such activities.
 * 
 * This script captures external connections and logs details about the source processes, their namespaces, 
 * and their destinations. It can be configured to filter connections going outside the cluster or only outside 
 * the namespace.
 * 
 * This script uses the following helpers:
 * - 'utils.nodeName': Retrieves the Kubeshark component node name. It is either the Worker hostname or the keyword 'hub'.
 * - 'jobs.schedule': Schedules a job to run at a specific time or interval.
 * - 'kfl.match': Filters data based on a KFL string.
 * - 'console.log': Logs a message to the console.
 * - 'console.clear': Clears all logged messages from the console.
 * 
 * **Configuration Options:**
 * - 'showOnlyExternal': When set to 'true', shows only connections going outside the cluster. 
 *   When 'false', includes connections going outside the namespace.
 * - 'matchStr': A KFL string to narrow the search scope (e.g., 'http', 'tcp', 'http and tls').
 * 
 * **Captured Data:**
 * - Source process name
 * - Source namespace
 * - Destination name or IP
 * - Destination namespace
 * - Count of connections to each destination
 * 
 * **Scheduler:**
 * If running on a Worker node, the 'printProcesses' function is scheduled to execute every 20 seconds, 
 * logging details of active processes and their destinations.
 * 
 * @file ReportExternalConnection.js
 * @example
 * // Configuration
 * var showOnlyExternal = true; // true: show only external cluster connections
 * var matchStr = 'http'; // Show only HTTP traffic
 */

// Configuration variables
var showOnlyExternal = true; // true: show only connections going outside the cluster. false: outside the namespace

// Store process data
var processes = {};
var processNamesToBlock = [
    // 'curl',
];

// Color variables
var blue = '[34m'; // Blue color for keys
var green = '[32m'; // Green color for values
var reset = '[0m'; // Reset to default color
var bold = '[1m'; // Bold text
var red = '[31m'; // Red color for error messages
var yellow = '[33m'; // Yellow color for warnings
var orange = '[38;5;214m'; // Approximation for orange in 256-color palette
var reset = "[0m"; // Reset to default color

/**
 * Checks if an IP address is public or private within the context of a Kubernetes cluster.
 * Private includes traditional private IP ranges, link-local addresses, and metadata service IPs.
 * 
 * @param {string} ip - The IP address to check.
 * @returns {boolean} - Returns true if the IP is public, otherwise false.
 */
function isPublicIP(ip) {
    try {
        var privateRanges = [
            /^10\\./, // 10.0.0.0 - 10.255.255.255
            /^172\\.(1[6-9]|2[0-9]|3[0-1])\\./, // 172.16.0.0 - 172.31.255.255
            /^192\\.168\\./, // 192.168.0.0 - 192.168.255.255
            /^127\\./, // 127.0.0.0 - 127.255.255.255 (loopback)
            // /^169\\.254\\./, // 169.254.0.0 - 169.254.255.255 (link-local, including 169.254.169.254)
            /^::1$/, // IPv6 loopback
            /^fc00:/, // IPv6 unique local address
            /^fe80:/, // IPv6 link-local address
            /^100\\.64\\./, // 100.64.0.0 - 100.127.255.255 (Carrier-Grade NAT)
            /^198\\.18\\./ // 198.18.0.0 - 198.19.255.255 (network testing)
        ];

        // Check if the IP matches any private or reserved range
        for (var i = 0; i < privateRanges.length; i++) {
            if (privateRanges[i].test(ip)) {
                return false; // IP is private or reserved
            }
        }

        // If no private ranges matched, the IP is public
        return true;
    } catch (e) {
        console.error('Error checking IP:', e);
        return false;
    }
}

/**
 * Captures and logs external connections based on the configured filters.
 * 
 * @param {object} data - The metadata object for a captured API call.
 */
function onItemCaptured(data) {
    try {
        // Filter data based on match string and connection type
        if (data && data.src && data.dst && data.dst.ip &&
            ((!showOnlyExternal && (data.src.namespace !== data.dst.namespace)) || 
            (showOnlyExternal && (data.dst.namespace === '') && isPublicIP(data.dst.ip))
        )) {
             
            var idx = data.src.processName + '|' + (data.src.name || data.src.ip) + '|' + (data.dst.name || data.dst.ip);

            // Initialize process data if not already tracked
            if (!processes[idx]) {
                processes[idx] = {
                    processName: data.src.processName,
                    ids: [],
                    podName: data.src.name || data.src.ip,
                    namespace: (data.src.namespace || 'External'),
                    destinations: {}
                };
            }

            // Track unique process IDs
            if (processes[idx].ids.indexOf(data.src.processId) === -1) {
                processes[idx].ids.push(data.src.processId);
            }

            // Track destinations and connection counts
            var dstName = data.dst.name || data.dst.ip;
            if (!processes[idx].destinations[dstName]) {
                processes[idx].destinations[dstName] = {
                    count: 0,
                    namespace: (data.dst.namespace || 'External')
                };
            }
            processes[idx].destinations[dstName].count++;

            // Block the pod if the process name is in the block list
            if (data.src.processName && data.src.pod && processNamesToBlock.indexOf(data.src.processName) !== -1) {
                console.log(red + 'Action Triggered: ' + bold + 'Block Pod\\n' + reset +
                    'Process: ' + red + data.src.processName + reset +
                    '\\nPod: ' + red + data.src.name + reset +
                    '\\nDestination: ' + red + dstName + reset +
                    '\\nServer response: ' + blue + hub.blockPod(data.src.name, data.src.namespace) + reset); 
            }
        }
    } catch (e) {
        console.error(e);
    }
}

/**
 * Logs the collected process and connection data in a structured format.
 */
function printProcesses() {
    try {
        // If no processes are tracked, skip logging
        if (Object.keys(processes).length === 0) {
            logMsg = 'No processes to log.';
        } else {
            var currentDate = new Date();
            var logMsg = red + currentDate.toString() + reset +'\\n';

            for (var idx in processes) {
                if (processes[idx].processName) {
                    logMsg += red + processes[idx].processName + reset + '.';
                }
                logMsg += processes[idx].podName + '.' + green + processes[idx].namespace + reset;
                logMsg += ' => ';
                for (var dest in processes[idx].destinations) {
                    logMsg += dest + '.' + green + processes[idx].destinations[dest].namespace + reset + ' (count: ' +
                        processes[idx].destinations[dest].count + '), ';
                }
                logMsg += '\\n';
            }
        }
        console.log(logMsg);
    } catch (e) {
        console.error(e);
    }
}

// Schedule the process printing job if running on a Worker node
if (utils.nodeName() !== 'hub') {
    jobs.schedule('print-processes', '*/20 * * * * *', printProcesses);
} else {
 console.log(blue + "Use: " + red + "--set tap.packetCapture=ebpf" + blue + " for this script to function most efficiently" + reset);
}


`
const SCRIPT_KINESIS = `// Export HTTP payloads in HAR format to a Kinesis stream

/*
 This script demonstrates exporting HTTP payloads in HAR format to a Kinesis stream.
 It exports the data in batches of a minimum size (env.KINESIS_MIN_BATCH_SIZE).
 The script expects the following environment variables to be set:
 - KINESIS_STREAM_NAME:    The name of the Kinesis stream to export the data to
 - AWS_REGION:             The AWS region where the Kinesis stream is located
 - AWS_ACCESS_KEY_ID:      The AWS access key ID to use for authentication
 - AWS_SECRET_ACCESS_KEY:  The AWS secret access key to use for authentication
 - KINESIS_MIN_BATCH_SIZE: The minimum number of items to batch before exporting to Kinesis
 */

 var dataArr = [];

/**
 * L7 Hook: This function is invoked for every emitted item, representing a reassembled message. 
 * It operates continuously, regardless of the state of the dashboard, whether open or closed.
 * 
 * This hook processes the entire metadata object provided as input. It enables inline processing 
 * of metadata, allowing for quick actions such as calculations or assignments based on the data. 
 * Complex or time-consuming operations should not be included in this function, as it executes 
 * inline with every API call. For such tasks, offload the processing to asynchronous jobs.
 *
 * @param {Object} data - The metadata object containing detailed information about the reassembled message. 
 * The exact structure can be referenced here: 
 * https://github.com/kubeshark/api/blob/856984a4d0fcccd0317da948c9e3059fdba59fcd/api.go#L330
 * @returns {void} - This function does not return any value.
 * 
 * @example
 * Key metadata fields available for inspection:
 * ==================================================
 * - data.dst.ip
 * - data.dst.name
 * - data.dst.namespace
 * - data.src.ip
 * - data.src.name
 * - data.src.namespace
 * - data.protocol.name
 * - data.request.method
 * - data.request.headers
 * - data.response.headers
 * - data.response.bodySize
 * - data.response.status
 * - data.response.statusText
 * - data.size
 * - data.responseSize
 * - data.requestSize
 * - data.elapsedTime
 * - data.timestamp
 * - data.stream
 * - data.startTime
 * - data.src.processName
 * - data.src.processId
 * - data.dst.processName
 * - data.dst.processId
 * 
 * @example
 * // Example 1: Capturing specific request path
 * function onItemCaptured(data) {
 *   console.log(data.Request.Path);
 *   if (data.Request.Path === "/health") {
 *     const response = vendor.webhook(
 *       "POST",
 *       env.WEBHOOK_URL,
 *       JSON.stringify(data),
 *       { "content-type": "application/json" }
 *     );
 *   }
 * }
 * 
 * @example
 * // Example 2: Logging the entire metadata object
 * function onItemCaptured(data) {
 *   console.log(JSON.stringify(data));
 * }
 */
 function onItemCaptured(data) {
     if (data.Protocol && data.Protocol.Name === "http") {
         dataArr.push(data);
     }
 }
 
 /*
  Schedules the job to export data to Kinesis at defined intervals using the jobs.schedule helper.
 
  The jobs.schedule function is used here to repeatedly execute the kinesisExportJob function
  every second, as specified by the cron expression * * * * * *.
 
  Example:
  jobs.schedule("kinesis-export", "* * * * * *", kinesisExportJob);
  */
 jobs.schedule("kinesis-export", "* * * * * *", kinesisExportJob);
 
 /*
  The kinesisExportJob function is executed at scheduled intervals to export
  batched HTTP payloads to a Kinesis stream.
 
  - If the number of items in the batch is below the minimum threshold (KINESIS_MIN_BATCH_SIZE),
    the function exits without exporting data.
  - Otherwise, it builds a HAR payload from the batched data and sends it to the Kinesis stream.
 
  Throws:
  - Logs any errors encountered during HAR creation or Kinesis export.
 
  Returns:
  - None
  */
 function kinesisExportJob() {
     try {
         if (dataArr.length < env.KINESIS_MIN_BATCH_SIZE) {
             console.log("No data to send to Kinesis: " + dataArr.length);
             return;
         }
 
         // The buildHAR function is provided by Kubeshark and expects an array of HTTP payloads
         var har = wrapper.buildHAR(dataArr);
 
         // Uncomment the following line to log the number of items being exported
         // console.log("Exporting " + dataArr.length + " items to Kinesis");
 
         // Reset the data array after building the HAR payload
         dataArr = [];
 
         // Export the HAR payload to Kinesis
         var k_ret = vendor.kinesis.put(
             env.KINESIS_STREAM_NAME,
             har,
             env.AWS_REGION,
             env.AWS_ACCESS_KEY_ID,
             env.AWS_SECRET_ACCESS_KEY
         );
 
         console.log("Kinesis Results: " + k_ret);
     } catch (e) {
         console.error(e);
     }
 }
 
`

const SCRIPT_KUBESHARK_LOGS = `// Kubeshark Logs and Pod Events Report
var logLevels = [
  "error",
  "warning",
  // "info",
];

var blue = "[34m"; // Blue color for keys
var green = "[32m"; // Green color for values
var reset = "[0m"; // Reset to default color
var bold = "[1m"; // Bold text
var red = "[31m"; // Red color for error messages
var yellow = "[33m"; // Yellow color for warnings
var orange = "[38;5;214m"; // Approximation for orange in 256-color palette

// Clear console and print header if running on the "hub" node
if (utils.nodeName() === "hub") {
  console.clear();
  console.log(bold + "Kubeshark Logs and Pod Events Report" + reset);
}

/**
 * Formats an object into a pretty ANSI-colored string for terminal logs.
 * If the object has a 'time' field, it is prepended to the log.
 * The remaining keys and values are styled with colors.
 * @param {Object} obj - The object to format.
 * @returns {string} - A formatted string.
 */
function prettyLog(obj) {
  var msg = "";

  // Check if the 'time' field exists and prepend it
  if (obj.hasOwnProperty("time")) {
    msg += green + obj["time"] + reset + " "; // Add time in green
    delete obj["time"]; // Remove 'time' key to avoid duplication
  }

  // Iterate over the remaining keys in the object
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      msg += blue + key + reset + ": " + green + obj[key] + reset + ", ";
    }
  }

  return msg.slice(0, -2); // Remove trailing ", "
}

/**
 * Logs messages with level-based prefixes.
 * Only logs messages with levels 'error' or 'warning'.
 * @param {string} level - The log level (e.g., "error", "warning").
 * @param {string|Object} msg - The log message, either a stringified JSON or an object.
 */
function onLogCall(level, msg) {
  // Only log relevant levels
  if (logLevels.indexOf(level) == -1)
    return;

  var prefix = "";
  switch (level) {
    case "info":
      prefix = green + "INF" + reset;
      break;
    case "error":
      prefix = red + "ERR" + reset;
      break;
    case "warning":
      prefix = yellow + "WRN" + reset;
      break;
    default:
      prefix = "LOG"; // Default log prefix for unknown levels
      break;
  }

  // Parse message if it's a JSON string
  var parsedMsg = typeof msg === "string" ? JSON.parse(msg) : msg;

  console.log(prefix + " " + prettyLog(parsedMsg));
}

/**
 * Logs Kubernetes pod events with relevant metadata.
 * Filters events for pods with the 'kubeshark' label.
 * Logs the 'CreationTimestamp', app label, and pod name.
 * @param {string} event - The event type (e.g., "ADDED", "MODIFIED").
 * @param {Object} pod - The Kubernetes pod object.
 */
function onPodEvent(event, pod) {
  // Ensure the pod has the required metadata and labels
  if (
    pod &&
    pod.ObjectMeta &&
    pod.ObjectMeta.Labels &&
    pod.ObjectMeta.Labels["app.kubernetes.io/name"] === "kubeshark"
  ) {
    // Deep copy ObjectMeta to avoid modifying the original object
    var parsedObjectMeta = JSON.parse(JSON.stringify(pod.ObjectMeta));

    console.log(
      orange + event + reset + " " + prettyLog({
        time: parsedObjectMeta.CreationTimestamp, // Creation timestamp
        app: pod.ObjectMeta.Labels["app.kubeshark.co/app"], // App label
        pod: pod.ObjectMeta.Name // Pod name
      })
    );
  }
}

`

const SCRIPT_DNS_TOP5 = `// Top 5 Pods Consuming the Most DNS Requests (Potentially Triggering DNS Rate Limiting)

// dnsCounts[podName] = count;
var dnsCounts = {};
var previousDnsCounts = {};
var workerTotalDnsCounts = {};
var scriptId = "dns2"; // Unique identifier for this script - very important to be unique. DO NOT DUPLICATE THIS FIELD TO ANOTHER SCRIPT

// Color variables
var blue = '[34m'; // Blue color for keys
var green = '[32m'; // Green color for values
var reset = '[0m'; // Reset to default color
var bold = '[1m'; // Bold text
var red = '[31m'; // Red color for error messages
var yellow = '[33m'; // Yellow color for warnings
var orange = '[38;5;214m'; // Approximation for orange in 256-color palette
var reset = "[0m"; // Reset to default color

var previousTime = Math.floor(Date.now() / 1000);

/**
 * L7 Hook: Capture DNS traffic and track request counts per pod.
 * @param {Object} data - Metadata object for a captured API call.
 */
function onItemCaptured(data) {
    try {
        if (data.protocol && data.protocol.name === "dns" && data.src && data.src.name) {
            var podName = data.src.name;
            if (!dnsCounts[podName])
                dnsCounts[podName] = 0;
            dnsCounts[podName]++;
            if (!workerTotalDnsCounts[podName])
                workerTotalDnsCounts[podName] = 0;
            workerTotalDnsCounts[podName]++;
        }
    } catch (e) {
        console.error("Error processing DNS traffic:", e);
    }
}

/**
 * Hook: onHubAction
 * 
 * This hook is called when the 'hub.action(action, object)' helper is invoked. While the helper
 * can be called from either the Hub or Workers, the hook is triggered exclusively on the Hub.
 *
 * This hook is particularly useful to consolidate objects created by the workers into a single object, ready for further processing.
 * In this example, we are consolidating the DNS counts from each worker into a single object, to generate a single report.
 *
 * This hook works only on the Hub.
 *
 * @param {string} action - A string indicating the type of action being performed (nothing really to do with this arg).
 * @param {Object} object - The object transmitted using the helper.
 *
 * @returns {void} - This function does not return any value.
 *
 */

function onHubAction(action, object) {
    // important to use the unique identifier to not mix up data with other scripts
    if (action == scriptId)
        dnsCounts = wrapper.joinSumMaps(dnsCounts, object);
}

function toPrometheusMetricName(input) {
    // Replace invalid characters (e.g., hyphens) with underscores
    var sanitized = input.replace(/[^a-zA-Z0-9_:]/g, '_');
    
    // Ensure the name starts with a valid character (a-z, A-Z, or _)
    if (!/^[a-zA-Z_:]/.test(sanitized)) {
        sanitized = '_' + sanitized;
    }
    
    // Return the sanitized metric name
    return sanitized;
}

/**
 * Report pod DNS activity metrics to Prometheus.
 */
function reportToPrometheus(dCounts) {
    try {
        for (var podName in dCounts) {
            var dnsCount = dCounts[podName];
            var labels = { 
              s_name: scriptId,
              s_pod: podName
            };
            prometheus.metric(toPrometheusMetricName(podName), "DNS request count per pod", 1, dnsCount, labels);
        }
    } catch (e) {
        console.error( "Error reporting to Prometheus:", e);
    }
}

/**
 * Report the top N pods consuming the most DNS calls.
 */
function getTopDNSConsumers(dCounts, topN) {
    try {
        var sortedPods = Object.keys(dCounts).sort(function (a, b) {
            return dCounts[b] - dCounts[a];
        });
        var topPods = {};
        for (var i = 0; i < Math.min(topN, sortedPods.length); i++) {
            var podName = sortedPods[i];
            topPods[podName] = dCounts[podName];
        }

        return topPods;
    } catch (e) {
        console.error("Error retrieving top DNS consumers:", e);
        return {};
    }
}


/**
 * Create a log message for the top DNS consumers.
 */
function createReport(dCounts) {
    try {
        var currentDate = new Date();
        var currentTime = Math.floor(Date.now() / 1000);
        var logMessage = bold + "Top Pods Consuming DNS Requests " + red + "(" + 
            currentDate.toString() + "):" + reset + "\\n";


        // Loop through the provided map and append to the log message
        var i = 0;
        var elapsedTime = currentTime - previousTime;
        for (var podName in dCounts) {
            if (dCounts.hasOwnProperty(podName)) {
                var podDisplayName = red + podName + reset; // Red for pod name
                var dnsCount = blue + dCounts[podName] + reset; // Blue for DNS count
                var countRate = blue + ( (dCounts[podName] - (previousDnsCounts[podName] || 0)) / elapsedTime) + " calls/s" + reset;
                logMessage += (++i) + ". Pod: " + podDisplayName + ", DNS Calls: " + dnsCount + 
                    ", Rate: " + countRate +  "\\n";
            }
        }
        previousTime = currentTime;
        previousDnsCounts = dCounts;
        return (i>0) ? logMessage : "Waiting for data ..";
    } catch (e) {
        console.error("Error creating log message for DNS consumers:", e);
        return "";
    }
}


// Scheduled reporting jobs
if (utils.nodeName() === "hub") 
    jobs.schedule("report-dns-consumers", "*/20 * * * * *", reportTopDNSConsumers);
else
    jobs.schedule("report-dns-consumers-to-hub", "*/20 * * * * *", workerReportToHub);
        
        
function reportTopDNSConsumers() {
    try {
        console.clear();
        console.log(createReport(getTopDNSConsumers(dnsCounts, 5)));
    } catch (e) {
        console.error("Error in hub reporting job:", e);
    }
}


function workerReportToHub() {
    try {
        reportToPrometheus(workerTotalDnsCounts);
        hub.action(scriptId, dnsCounts);
        dnsCounts = {};
    } catch (e) {
        console.error("Error in worker reporting job:", e);
    }
}

`
const SCRIPT_DNS_TOP5_SERVERS = `// Top 5 DNS Servers Consuming the Most DNS Requests

/*
 * Prompt: Write a network processor script that reports the top requested DNS endpoints by DNS server.
 * 
 * Hook: onItemCaptured
 * - This hook captures network messages processed by Kubeshark and filters DNS traffic.
 * - DNS messages are identified by data.protocol.name == 'dns'.
 * - Each message contains:
 *     - data.src.name: The client making the request.
 *     - data.dst.name: The DNS server (fallback to 'data.dst.ip' if empty).
 *     - data.dst.namespace: The namespace of the DNS server. Defaults to "External" if empty.
 * - The hook maintains a map:
 *     - Key: DNS server identifier (domain or IP, including namespace if available).
 *     - Value: Another map with:
 *         - Key: Endpoint name (from 'data.response.answers[i].name').
 *         - Value: Count of requests for the endpoint.
 * 
 * Hook: onHubAction
 * - This hook consolidates DNS data from all worker nodes into a single map ('hubDnsServerMap').
 * - Workers send their DNS maps periodically to the hub using the 'hub.action()' helper.
 * - The hub merges these maps using the 'wrapper.joinSumMaps()' function for centralized reporting.
 * 
 * Scheduled Jobs:
 * - Hub:
 *     - Schedules a job to report the top 5 DNS endpoints for each server every 20 seconds.
 * - Worker:
 *     - Schedules a job to send the DNS map to the hub every 10 seconds.
 * 
 * Report:
 * - The report includes:
 *     - DNS server names highlighted in blue.
 *     - Endpoint names highlighted in green.
 *     - Counts in bold.
 *     - The total requests per server and the top 5 endpoints.
 */

// Global DNS server map
//map[dnsServerIdentifier][endpointName] = count;
var dnsServerMap = {};

var scriptId = "dns3"; // Unique identifier for this script - very important to be unique. DO NOT DUPLICATE THIS FIELD TO ANOTHER SCRIPT

// Color variables
var blue = '[34m'; // Blue color for keys
var green = '[32m'; // Green color for values
var reset = '[0m'; // Reset to default color
var bold = '[1m'; // Bold text
var red = '[31m'; // Red color for error messages
var yellow = '[33m'; // Yellow color for warnings
var orange = '[38;5;214m'; // Approximation for orange in 256-color palette
var reset = "[0m"; // Reset to default color

/**
 * Hook: onHubAction
 * - Consolidates DNS data from all worker nodes into the hub's map (hubDnsServerMap).
 * - Uses the 'joinSumMaps()' helper to merge worker data.
 * @param {string} action - The action identifier (unused here).
 * @param {object} object - The DNS data map sent from a worker.
 */
function onHubAction(action, object) {
    // important to use the unique identifier to not mix up data with other scripts
    // user joinSumMaps to add the counts
    if (action == scriptId)
        dnsServerMap = wrapper.joinSumMaps(dnsServerMap, object);
}

/**
 * Hook: onItemCaptured
 * - Processes each DNS message captured by Kubeshark.
 * - Updates the local map (workerDnsServerMap) with endpoint request counts.
 * @param {object} data - The network message metadata.
 */
function onItemCaptured(data) {
    try {
        // filter DNS traffic with responses
        // make sure there endpoints in the query
        if (data.protocol && data.protocol.name === "dns" && 
            data.src && data.dst && data.response && data.request.questions) {

            var dnsServer = data.dst.name || data.dst.ip;
            var namespace = data.dst.namespace || "External";
            var dnsServerIdentifier = dnsServer + " (" + namespace + ")";

            if (!dnsServerMap[dnsServerIdentifier]) {
                dnsServerMap[dnsServerIdentifier] = {};
            }

            for (var i = 0; i < data.request.questions.length; i++) {
                var endpointName = data.request.questions[i].name;

                if (endpointName) {
                    if (!dnsServerMap[dnsServerIdentifier][endpointName]) {
                        dnsServerMap[dnsServerIdentifier][endpointName] = 0;
                    }
                    dnsServerMap[dnsServerIdentifier][endpointName]++;
                }
            }
        }
    } catch (e) {
        console.error("Error processing DNS data:", e);
    }
}

/**
 * Generates a report of the top 5 endpoints for each DNS server.
 * - Consolidates data from the global hubDnsServerMap.
 * - Formats the report with color and bold text.
 * @returns {string} - The formatted report.
 */
function createReport() {
    try {
        var report = "Top 5 Requested DNS Endpoints By DNS Server:\\n";

        for (var dnsServer in dnsServerMap) {
            if (dnsServerMap.hasOwnProperty(dnsServer)) {
                var totalRequests = 0;
                for (var endpoint in dnsServerMap[dnsServer]) {
                    if (dnsServerMap[dnsServer].hasOwnProperty(endpoint)) {
                        totalRequests += dnsServerMap[dnsServer][endpoint];
                    }
                }

                report += bold + blue + "DNS Server: " + dnsServer + reset + " - Total Requests: " + bold + totalRequests + reset + "\\n";

                var endpoints = [];
                for (var endpoint in dnsServerMap[dnsServer]) {
                    if (dnsServerMap[dnsServer].hasOwnProperty(endpoint)) {
                        endpoints.push({ name: endpoint, count: dnsServerMap[dnsServer][endpoint] });
                    }
                }

                endpoints.sort(function (a, b) {
                    return b.count - a.count;
                });

                for (var i = 0; i < Math.min(5, endpoints.length); i++) {
                    report += "  " + (i + 1) + ". " + green + endpoints[i].name + reset + " - Requests: " + bold + endpoints[i].count + reset + "\\n";
                }
            }
        }

        return report || "No DNS requests tracked yet.";
    } catch (e) {
        console.error(bold + "Error generating DNS server report:" + reset, e);
        return "Error generating report.";
    }
}

// Scheduled jobs
if (utils.nodeName() === "hub") {
    jobs.schedule("report-dns-endpoints", "*/20 * * * * *", reportTopDNSEndpoints);
} else {
    jobs.schedule("worker-report-to-hub", "*/10 * * * * *", workerReportToHub);
}

/**
 * Periodic job: Generates and logs the DNS report on the hub.
 */
function reportTopDNSEndpoints() {
    try {
        console.clear();
        console.log(createReport());
    } catch (e) {
        console.error("Error in hub reporting job:", e);
    }
}

/**
 * Periodic job: Sends worker DNS data to the hub and resets the local map.
 */
function workerReportToHub() {
    try {
        // important to use the unique identifier to not mix up data with other scripts
        hub.action(scriptId, dnsServerMap);
        reportToPrometheus(dnsServerMap)
        dnsServerMap = {};
    } catch (e) {
        console.error("Error in worker reporting job:", e);
    }
}

/**
 * Report pod DNS activity metrics to Prometheus.
 */

function toPrometheusMetricName(input) {
    // Replace invalid characters (e.g., hyphens) with underscores
    var sanitized = input.replace(/[^a-zA-Z0-9_:]/g, '_');
    
    // Ensure the name starts with a valid character (a-z, A-Z, or _)
    if (!/^[a-zA-Z_:]/.test(sanitized)) {
        sanitized = '_' + sanitized;
    }
    
    // Return the sanitized metric name
    return sanitized;
}
    
/**
 * Reports DNS metrics to Prometheus.
 * Sends metrics with the format:
 * - Metric Name: dns_server
 * - Labels: 
 *   - s_server: The DNS server name (identifier).
 *   - s_endpoint: The resolved DNS endpoint.
 * - Value: The count of requests.
 */
function reportToPrometheus(map) {
    try {
        for (var dnsServer in map) {
            if (map.hasOwnProperty(dnsServer)) {
                for (var endpoint in map[dnsServer]) {
                    if (map[dnsServer].hasOwnProperty(endpoint)) {
                        // Metric name: dns_server
                        var metricName = toPrometheusMetricName(dnsServer + "_" + endpoint);

                        // Labels: s_server and s_endpoint
                        var labels = {
                            s_server: dnsServer,
                            s_endpoint: endpoint,
                            s_name: scriptId
                        };

                        // Value: Count of requests
                        var value = map[dnsServer][endpoint];

                        // Send the metric to Prometheus
                        prometheus.metric(metricName, "endpoint DNS request count by DNS server", 1, value, labels);
                    }
                }
            }
        }
    } catch (e) {
        console.error("Error reporting metrics to Prometheus:", e);
    }
}

`

const SCRIPT_API_LATENCY_ANOMALIES = `// Report API Latency Anomalies Using Z-Score Method
/**
 * Tracks latency measures per pod and detects anomalies using the Z-Score method.
 * Captures latency data from the L7 hook using 'data.elapsedTime' in microseconds
 * and processes it in a scheduled job to calculate stats.
 * Options:
 * - filterExpression: Filter expression for the L7 hook (e.g. "http")
 * - zScoreThreshold: Z-Score threshold for anomaly detection
 * - rollingWindow: Rolling window size for latency measures
 * - minSampleSize: Minimum sample size for meaningful stats
 */


var filterExpression = "http"; // Filter expression for the L7 hook
var zScoreThreshold = 3; // Z-Score threshold for anomaly detection
var rollingWindow = 1000; // Rolling window size for latency measures
var minSampleSize = 40; // Minimum sample size for meaningful stats

// Object to store latency measures per pod and path
var latencyMeasures = {};
var podStats = {}; // Object to hold calculated stats (mean, stdDev) for each pod and path

var blue = "[34m"; // Blue color for keys
var green = "[32m"; // Green color for values
var reset = "[0m"; // Reset to default color
var bold = "[1m"; // Bold text
var red = "[31m"; // Red color for error messages
var yellow = "[33m"; // Yellow color for warnings
var orange = "[38;5;214m"; // Approximation for orange in 256-color palette


/**
 * L7 Hook: Captures latency data using 'data.elapsedTime' (in microseconds) and stores it.
 * Tests the newly added value for anomalies using pre-calculated stats from the scheduled job.
 * 
 * @param {Object} data - Metadata object for the captured API call.
 */
function onItemCaptured(data) {
    try {
        if (kfl.match(filterExpression, data) && data.elapsedTime && data.src && data.src.name) {
            var podName = data.src.name; // Use the pod name as the key
            var path = data.request && data.request.path ? data.request.path : "nopath"; // Default to "nopath" if undefined
            var latency = data.elapsedTime; // Latency value in microseconds

            if (latency < 0) {
                console.error(red + "Negative latency detected for pod:" + podName + ", Value:" + latency + "µs. Skipping." + reset);
                return;
            }

            // Initialize the pod's data structure if not already present
            if (!latencyMeasures[podName]) {
                latencyMeasures[podName] = {};
            }
            if (!latencyMeasures[podName][path]) {
                latencyMeasures[podName][path] = [];
            }
            if (!podStats[podName]) {
                podStats[podName] = {};
            }

            // Add the latency measure and ensure the array does not exceed 1000 values
            latencyMeasures[podName][path].push(latency);
            if (latencyMeasures[podName][path].length > rollingWindow) {
                latencyMeasures[podName][path].shift(); // Remove the oldest value
            }

            // Detect anomaly using pre-calculated stats
            if (podStats[podName][path]) {
                var mean = podStats[podName][path].mean;
                var stdDev = podStats[podName][path].stdDev;

                if (stdDev > 0) {
                    var zScore = (latency - mean) / stdDev;
                    if (Math.abs(zScore) > zScoreThreshold) {
                        console.log(
                            bold + blue + podName + "/" + path + reset,
                            "| Latency:", red + latency, "µs" + reset,
                            "| Z-Score:", red + zScore.toFixed(2) + reset,
                            "(Mean:", green + mean.toFixed(2) + "µs" + reset,
                            "| StdDev:", green + stdDev.toFixed(2) + "µs" + reset,
                            "| Sample Size:", latencyMeasures[podName][path].length + ")"
                        );
                    }
                }
            }
        }
    } catch (e) {
        console.error(red + "Error capturing latency data: " + e.message + reset);
    }
}

/**
 * Calculates the mean and standard deviation for a dataset.
 * @param {number[]} data - Array of latency measures
 * @returns {{mean: number, stdDev: number}} - Object containing mean and standard deviation
 */
function calculateMeanAndStdDev(data) {
    if (!data || data.length === 0) {
        return { mean: 0, stdDev: 0 };
    }

    var sum = 0;
    for (var i = 0; i < data.length; i++) {
        sum += data[i];
    }
    var mean = sum / data.length;

    var varianceSum = 0;
    for (var i = 0; i < data.length; i++) {
        varianceSum += Math.pow(data[i] - mean, 2);
    }
    var variance = varianceSum / data.length;
    var stdDev = Math.sqrt(variance);

    return { mean: mean, stdDev: stdDev };
}

/**
 * Scheduled Job: Processes stored latency measures and calculates stats for each pod.
 * Updates the podStats object with mean and standard deviation for each pod.
 */
function analyzeLatencies() {
    try {
        for (var podName in latencyMeasures) {
            if (latencyMeasures.hasOwnProperty(podName)) {
                for (var path in latencyMeasures[podName]) {
                    var measures = latencyMeasures[podName][path];

                    if (measures.length >= minSampleSize) { // Ensure enough data for meaningful stats
                        // Calculate mean and standard deviation
                        var stats = calculateMeanAndStdDev(measures);
                        if (!podStats[podName]) {
                            podStats[podName] = {};
                        }
                        podStats[podName][path] = stats;
                    }

                    // Trim the array to the last 1000 values as a precaution
                    if (measures.length > rollingWindow) {
                        latencyMeasures[podName][path] = measures.slice(-1 * rollingWindow);
                    }
                }
            }
        }
    } catch (e) {
        console.error(red + "Error analyzing latencies: " + e.message + reset);
    }
}

// Schedule the job to run every 10 seconds
if (utils.nodeName() !== "hub") {
    jobs.schedule("analyze-latencies", "*/10 * * * * *", analyzeLatencies);
} else {
    console.clear();
    console.log(bold + "Anomaly detection initialized. Anomalies will be reported if Z-Score > " + red + zScoreThreshold + reset);
}

`

const SCRIPT_INACTIVE_PODS = `// Inactive Pods (Cost Optimization)
// Inactive Pods (Cost Optimization)
/**
 * Pod Traffic Monitoring Script for Cost Optimization
 * This script monitors network activity for pods on nodes and reports the pods per node that show no network activity.
 */

var nodeName = utils.nodeName(); // Current node name
var activePods = {}; // Tracks network activity per pod
var metricName = "pod_traffic"; // Prometheus metric name
var scriptId = "podtraffic"; // Unique identifier for this script


var blue = "[34m"; // Blue color for keys
var green = "[32m"; // Green color for values
var reset = "[0m"; // Reset to default color
var bold = "[1m"; // Bold text
var red = "[31m"; // Red color for error messages
var yellow = "[33m"; // Yellow color for warnings
var orange = "[38;5;214m"; // Approximation for orange in 256-color palette

/**
 * onItemCaptured hook
 * Updates the pods map setting activity to true for active pods.
 * Inactive pods will not have a presence in the map.
 * This hook runs only on the worker nodes.
 * @param {object} data - Metadata for each captured API call.
 */
function onItemCaptured(data) {
    try {
        if (data.src && data.src.name) 
            activePods[data.src.name] = true;
        if (data.dst && data.dst.name) 
            activePods[data.dst.name] = true;
    } catch (e) {
        console.error("Error processing captured data:", e);
    }
}

/**
 * onHubAction hook
 * Consolidates the active pods from all workers into a single object.
 * This hook works only on the Hub.
 * @param {string} action - Action identifier for this script.
 * @param {object} object - The map to be consolidated.
 */
function onHubAction(action, object) {
    if (action === scriptId)
        activePods = wrapper.joinReplaceMaps(activePods, object);
}

/**
 * preparePods function
 * Filters the active pods from the general list of pods, leaving only those that are not active.
 * @param {object} pods - The general list of pods.
 * @returns {object} Node-specific pods map with their activity status.
 */
function preparePods(pods) {
    var nodePods = {};
    try {
        var targets = JSON.parse(hub.targeted());
        for (var i = 0; i < targets.length; i++) {
            var podName = targets[i].metadata.name;
            nodePods[podName] = pods[podName] || false;
        }
        return nodePods;
    } catch (e) {
        console.error("Error preparing pods list:", e);
    }
}

/**
 * printReport function
 * Generates and prints a report of pods showing network activity.
 * @param {object} pods - Map of pods with their network activity status.
 */
function printReport(pods) {
    try {
        var inactivePods = [];
        var totalPods = Object.keys(pods).length;

        // Separate active and inactive pods
        for (var pod in pods) {
            if (!pods[pod]) {
                inactivePods.push(pod);
            }
        }

        var totalInactive = inactivePods.length;

        // Build log message
        var logMessage = "";
        logMessage += bold + "Pod Activity Report:" + reset + "\\n";
        logMessage += red + "***Make sure the TCP dissector is active for this script to perform most efficiently***" + reset + "\\n";
        logMessage += "Total Pods: " + blue + totalPods + reset + "\\n";
        logMessage += "Inactive Pods: " + blue + totalInactive + reset + "\\n";
        if (totalInactive > 0) {
            logMessage += bold + "List of Inactive Pods:" + reset + "\\n";
            for (var i = 0; i < inactivePods.length; i++) {
                logMessage += "- " + red + inactivePods[i] + reset + "\\n";
            }
        } else {
            logMessage += "All pods are active!\\n";
        }

        console.log(logMessage.trim());
    } catch (e) {
        console.error("Error generating report:", e);
    }
}

// Schedule jobs based on the current node
if (nodeName === "hub") {
    jobs.schedule("print-report", "*/20 * * * * *", hubJob);
} else {
    jobs.schedule("send-to-hub", "*/10 * * * * *", workerJob);
}

/**
 * workerJob function
 * Sends active pods data to the Hub.
 */
function workerJob() {
    try {
        hub.action(scriptId, activePods);
    } catch (e) {
        console.error("Error sending data to hub:", e);
    }
}

/**
 * hubJob function
 * Generates and prints the report of pods on the Hub.
 */
function hubJob() {
    try {
        console.clear();
        printReport(preparePods(activePods));
    } catch (e) {
        console.error("Error in hub reporting job:", e);
    }
}

`


const EXAMPLE_SCRIPTS = [
  SCRIPT_BOOTSTRAP,
  SCRIPT_DNS_TOP5,
  SCRIPT_DNS_TOP5_SERVERS,
  SCRIPT_API_LATENCY_ANOMALIES,
  SCRIPT_EXTERNAL_CONNECTION,
  SCRIPT_INACTIVE_PODS,
  SCRIPT_CUSTOM_PROMETHEUS_METRIC,
  SCRIPT_PRINT_ENV,
  SCRIPT_LOG_TOTAL_CAPTURED_PACKET_KB_PER_MIN,
  SCRIPT_KINESIS,
  SCRIPT_ERROR_HANDLING,
  SCRIPT_WEBHOOK,
  SCRIPT_S3,
  SCRIPT_KUBESHARK_LOGS,
  // SCRIPT_SLACK,
  // SCRIPT_MONITORING_PASS_HTTP,
]

const EXAMPLE_SCRIPT_TITLES = [
  "Empty",
  "Top 5 Pods Consuming the Most DNS Requests",
  "Top requested DNS endpoints by DNS server",
  "Report API Latency Anomalies",
  "Report External Communication",
  "Inactive Pods (Cost Optimization)",
  "Export Custom Prometheus Metric",
  "Print Environment Variables",
  "Log Total Captured Packet and KB Every Minute",
  "Export HTTP payloads in HAR format to an AWS Kinesis stream",
  "Error Handling",
  "Call a Webhook",
  "Upload PCAP File (e.g. PCAP) to an AWS S3 Bucket",
  "Kubeshark Logs and Pod Events Report",
  // "Report To a Slack Channel If HTTP Status Code is 500",
  // "Monitoring: Fail HTTP Status Code is 500, Pass Anything Else",
]

const TEMPLATE_SCRIPT_TITLES = {
  "Bootstrap Script": <CodeRoundedIcon />,
  "Top 5 DNS Consumers": <DnsRoundedIcon />,
  "Top requested DNS endpoints by DNS server": <PublicIcon />,
  "Report API Latency Anomalies": <InsightsIcon />,
  "Pods and Processes With External Communication": <ConnectWithoutContactRoundedIcon />,
  "Inactive Pods (Cost Optimization)": <MoneyOffIcon />,
  "Export Custom Prometheus Metric": <Filter1RoundedIcon />,
  "Print Environment Variables": <CodeRoundedIcon />,
  "Log Total Captured Packet and KB Every Minute": <ArticleRoundedIcon />,
  "Export HTTP payloads in HAR format to an AWS Kinesis stream": <WaterfallChartRoundedIcon />,
  "Error Handling": <ErrorRoundedIcon />,
  "Call a Webhook": <WebhookRoundedIcon />,
  "Upload PCAP File (e.g. PCAP) to an AWS S3 Bucket": <CloudUploadRoundedIcon />,
  "Kubeshark Logs and Pod Events Report": <ArticleRoundedIcon />,
  // "Report To a Slack Channel If HTTP Status Code is 500": <HttpRoundedIcon />,
  // "Monitoring: Fail HTTP Status Code is 500, Pass Anything Else": <HttpRoundedIcon />,
  // "Aggregate the HTTP Status Codes and Push Them to InfluxDB Every Minute": <HttpRoundedIcon />,
  // "Aggregate the HTTP Status Codes and Push Them to Elastic Cloud Every Minute": <HttpRoundedIcon />,
  // "Upload a PCAP Snapshot to an AWS S3 Bucket If HTTP Status Code is 500": <CloudUploadRoundedIcon />,
  // "Use ChatGPT to Detect Unprocessable HTTP Requests": <ChatRoundedIcon />,
}


const DEFAULT_TITLE = "New Script"
const DEFAULT_SCRIPT = SCRIPT_EMPTY

export {
  SCRIPT_BOOTSTRAP,
  SCRIPT_PRINT_ENV,
  SCRIPT_ERROR_HANDLING,
  SCRIPT_CUSTOM_PROMETHEUS_METRIC,
  SCRIPT_WEBHOOK,
  SCRIPT_SLACK,
  SCRIPT_LOG_TOTAL_CAPTURED_PACKET_KB_PER_MIN,
  SCRIPT_MONITORING_PASS_HTTP,
  SCRIPT_INFLUXDB,
  SCRIPT_ELASTIC,
  SCRIPT_S3,
  SCRIPT_S3_SNAPSHOT,
  SCRIPT_CHATGPT,
  EXAMPLE_SCRIPTS,
  EXAMPLE_SCRIPT_TITLES,
  TEMPLATE_SCRIPT_TITLES,
  DEFAULT_TITLE,
  DEFAULT_SCRIPT,
}
