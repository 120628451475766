import React from 'react'
import variables from '../../../../variables.module.scss'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import { CircularProgress, Fab } from '@mui/material'

interface ActivateScriptButtonProps {
  darkModeEnabled: boolean
  inProgress: boolean
  onClick: () => void
}

export const ActivateScriptButton: React.FC<ActivateScriptButtonProps> = ({ darkModeEnabled, inProgress, onClick }) => {
  return (
    <Fab
      variant='extended'
      color='success'
      onClick={onClick}
      sx={{
        backgroundColor: darkModeEnabled
          ? variables.lightSuccessColor
          : variables.successColor,
        opacity: inProgress ? 0.8 : 1,
        pointerEvents: inProgress ? 'none' : 'all',
      }}
    >
      {inProgress && <CircularProgress size={14} color='inherit' sx={{ mr: '10px' }} />}
      {!inProgress && (
        <PlayArrowRoundedIcon
          htmlColor={
            darkModeEnabled
              ? variables.successColor
              : variables.mainBackgroundColor
          }
          sx={{ mr: '10px' }}
        />
      )}
      {inProgress ? 'Activating now...' : 'Activate'}
    </Fab>
  )
}
