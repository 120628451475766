import * as React from 'react';
import { Dialog, DialogTitle, Tabs, Tab } from '@mui/material';

import variables from '../../variables.module.scss'
import styles from './CreateRecordingDialog.module.sass';
import { StartRecordingJobView } from './StartRecordingJobView/StartRecordingJobView';
import { AllRecordingsView } from './AllRecordingsView/AllRecordingsView';
import { useSetRecoilState } from 'recoil'
import createRecordingDialogOpenAtom from "../../recoil/createRecordingDialogOpen";

export const CreateRecordingDialog = () => {
  const setOpen = useSetRecoilState(createRecordingDialogOpenAtom);

  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentTab(0)
  }

  React.useEffect(() => {
    if (!open) {
      handleClose()
    }
  }, [open])

  return (
    <>
      <Dialog
        open
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        style={{ color: variables.blueColor }}
      >
        <DialogTitle style={{ fontWeight: 'bold', color: variables.blueColor }}>
          <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Start a Recording Job" className={styles.Tab} />
            <Tab label="All Recordings" className={styles.Tab} />
          </Tabs>
        </DialogTitle>
        {currentTab === 0 && <StartRecordingJobView />}
        {currentTab === 1 && <AllRecordingsView switchToStartRecordingJob={() => setCurrentTab(0)} />}
      </Dialog>
    </>
  )
}
