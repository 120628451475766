import React, { useEffect, useRef, useState } from 'react'
import { Box, Tooltip } from '@mui/material'

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import variables from '../../../../variables.module.scss'
import styles from './ConsoleTab.module.sass'
import { Dropdown } from '../../../UI/Dropdown/Dropdown'
import { useRecoilValue } from 'recoil'
import scriptLogsAtom from '../../../../recoil/scriptLogs'
import { DropdownTab } from './DropdownTab'
import { UnreadDot } from '../../../UI/UnreadDot/UnreadDot'

export interface Tab {
  title?: string
  index?: number
  selected?: boolean
  selectedIndex?: number
  unread?: boolean
  onSelection?: (index: number, isDropdownTab?: boolean) => void
}

export interface ConsoleTabProps extends Tab {
  asDropdown?: boolean
  dropdownTabs?: Tab[]
}

export const ConsoleTab: React.FC<ConsoleTabProps> = ({
  title,
  index,
  selected = false,
  selectedIndex,
  unread = false,
  onSelection,
  asDropdown = false,
  dropdownTabs = []
}) => {
  const scriptLogs = useRecoilValue(scriptLogsAtom)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const dropdownAnchorRef = useRef(null)

  const [selectedDropdownTabTitle, setSelectedDropdownTabTitle] = useState(null)

  useEffect(() => {
    dropdownTabs.map((tab) => {
      if (tab.selected) {
        setSelectedDropdownTabTitle(tab.title)
      }
    })
  }, [dropdownTabs])

  return (
    <Tooltip title={
      !asDropdown ? (
        selected ? `Showing logs for selected script` : `Click to show logs for this script`
      ) : ''
    } placement='bottom' arrow>
      <Box
        className={`${styles.ConsoleTab} ${selected ? styles.ConsoleTabSelected : ''}`}
        onClick={() => asDropdown ? setDropdownOpen(!dropdownOpen) : onSelection(index)}
      >
        {!asDropdown && (
          <Box className={`${styles.ConsoleTabText} ${asDropdown ? styles.DropdownTabTitle : ''}`}>
            {title}
            {unread && <UnreadDot />}
          </Box>
        )}
        {asDropdown && (
          <Dropdown
            open={dropdownOpen}
            setOpen={setDropdownOpen}
            anchorElement={
              <Box
                boxSizing='border-box'
                position='relative'
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                ref={dropdownAnchorRef}
              >
                {selectedDropdownTabTitle === null && (
                  <p className={`${styles.ConsoleTabText} ${asDropdown ? styles.DropdownTabTitle : ''}`}>
                    + {dropdownTabs.length} script logs
                  </p>
                )}
                {selectedDropdownTabTitle !== null && (
                  <p className={`${styles.ConsoleTabText} ${asDropdown ? styles.DropdownTabTitle : ''}`}>
                    {selectedDropdownTabTitle}
                  </p>
                )}
                <ExpandMoreRoundedIcon htmlColor={variables.lighterGrayColor} sx={{ marginRight: '10px' }} />
                {unread && <UnreadDot />}
              </Box>
            }
            anchorRef={dropdownAnchorRef}
            anchorWrapStyles={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%'
            }}
            placement='bottom-start'
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [10, 10]
                }
              }
            ]}
            growFrom='right top'
          >
            <Box
              boxSizing='border-box'
              padding='12px'
              display='flex'
              alignItems='center'
              flexDirection='column'
              gap='10px'
              borderRadius='4px'
              border={`1px solid ${variables.slateColor}`}
              bgcolor={variables.githubEditorBackgroundColorLight}
              boxShadow={variables.lightShadow}
              maxHeight='400px'
              overflow='auto'
            >
              {dropdownTabs.map((tab: Tab) => {
                return (
                  <DropdownTab
                    key={tab.index}
                    index={tab.index}
                    selected={selectedIndex === tab.index}
                    selectedIndex={tab.selectedIndex}
                    unread={scriptLogs[tab.index]?.unread}
                    onSelection={tab.onSelection}
                    title={tab.title}
                    setDropdownOpen={setDropdownOpen}
                  />
                )
              })}
            </Box>
          </Dropdown>
        )}
      </Box>
    </Tooltip>
  )
}
