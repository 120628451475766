import React from 'react'
import variables from '../../../../variables.module.scss'
import { StopRounded } from '@mui/icons-material'
import { Box } from '@mui/material'

interface CheckboxIconProps {
  checked?: boolean
}

export const CheckboxIcon: React.FC<CheckboxIconProps> = ({checked}) => {
  return (
    <Box
      boxSizing='border-box'
      display='flex'
      alignItems='center'
      borderRadius='6px'
      bgcolor={variables.mainBackgroundColor}
      border={`1px solid ${variables.lighterGrayColor}`}
    >
      <StopRounded
        htmlColor={checked ? variables.blueColor : variables.mainBackgroundColor}
        sx={{
          fontSize: '18px',
        }}
      />
    </Box>
  )
}
