import React from 'react'
import variables from '../../../variables.module.scss'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import { Fab } from '@mui/material'

interface GoToScriptButtonProps {
  darkModeEnabled: boolean
  onClick: () => void
}

export const GoToScriptButton: React.FC<GoToScriptButtonProps> = ({ darkModeEnabled, onClick }) => {
  return (
    <Fab
      variant='extended'
      color='default'
      onClick={onClick}
      sx={{
        backgroundColor: darkModeEnabled
          ? variables.githubEditorBackgroundColorLight
          : variables.slateColor,
        color: darkModeEnabled
          ? variables.lighterGrayColor
          : variables.mainBackgroundColor,
        '&:hover': {
          backgroundColor: darkModeEnabled
            ? variables.githubEditorBackgroundColorLight
            : variables.slateColor
        }
      }}
    >
      <CodeRoundedIcon
        htmlColor={
          darkModeEnabled
            ? variables.lighterGrayColor
            : variables.mainBackgroundColor
        }
        sx={{ mr: '10px' }}
      />
      Go to Script
    </Fab>
  )
}
