import React, { useCallback, useState } from 'react'

import { Box, Typography, Grow, Button, Tooltip, Grid } from '@mui/material'
import CodeEditor from '@uiw/react-textarea-code-editor';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleIcon from '@mui/icons-material/Circle'
import PendingRoundedIcon from '@mui/icons-material/PendingRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { amber as muiAmberColor } from '@mui/material/colors'

import variables from '../../../../variables.module.scss';
import { ColorRed } from '../../../../consts'

const renderSettingStatus = (changing: boolean, active: boolean, error: boolean, length: number): JSX.Element => {
  let statusIndicator: JSX.Element = null

  if (active) {
    statusIndicator = <CheckCircleRoundedIcon fontSize='small' htmlColor={variables.successColor} />
  }

  if (!changing) {
    if (length === 0) {
      statusIndicator = <CircleIcon fontSize='small' htmlColor={variables.dataBackgroundColor} />
    }
  }

  if (changing) {
    statusIndicator = <PendingRoundedIcon fontSize='small' htmlColor={muiAmberColor[400]} />
  }

  if (error) {
    statusIndicator = <ErrorRoundedIcon fontSize='small' htmlColor={variables.failureColor} />
  }

  return statusIndicator
}

interface SettingProps {
  icon: JSX.Element
  title: string
  placeholder: string
  gridEnabled?: boolean
  editable?: boolean

  handleValueChange: (val: string) => void

  value: string|Array<string>
  changing: boolean
  setChanging: (val: boolean) => void
  active: boolean
  error: boolean

  postValue: () => Promise<void>
}

export const SettingInput: React.FC<SettingProps> = ({ icon, title, placeholder, gridEnabled = true, editable = true, handleValueChange, value, changing, setChanging, active, error, postValue }) => {
  const [hovered, setHovered] = useState(false)
  const [applyButtonClicked, setApplyButtonClicked] = useState(false)

  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  const wrapWithGrid = useCallback((children) => {
    return gridEnabled ? (
      <Grid item xs={6} sm={6} md={6}>{children}</Grid>
    ) : children
  }, [gridEnabled])

  const handlePostValue = () => {
    setApplyButtonClicked(true)
    postValue().then(() => setApplyButtonClicked(false))
  }

  return wrapWithGrid(
    <Box
      boxSizing='border-box'
      width={gridEnabled ? 'auto' : '100%'}
      padding='8px'
      borderRadius='6px'
      border={`1px solid ${error ? variables.failureColor : variables.lightestSlateColor}`}
      bgcolor={variables.dataBackgroundColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        borderColor: hovered ? `${variables.blueColor} !important` : null,
        transition: 'all .2s ease-in'
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        mt='3px'
        mb='12px'
      >
        <Box display='flex' alignItems='center' gap='10px'>
          {icon}
          <Typography
            variant='body2'
            fontFamily={variables.textFontFamily}
            color={variables.fontColor}
            textTransform='uppercase'
            fontWeight={600}
          >
            {title}
          </Typography>
        </Box>
        {renderSettingStatus(
          changing,
          active,
          error,
          value.length
        )}
      </Box>
      <Box maxHeight='120px' overflow='auto'>
        <CodeEditor
          disabled={!editable}
          value={value}
          language='py'
          data-color-mode='light'
          placeholder={placeholder}
          onChangeCapture={() => setChanging(true)}
          onChange={(event) => handleValueChange(event.target.value)}
          padding={8}
          style={{
            borderRadius: '4px',
            fontSize: 14,
            backgroundColor: error ? ColorRed : variables.mainBackgroundColor,
            fontFamily:
              'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
          }}
        />
      </Box>
      {changing && (
        <Grow in={changing}>
          <Tooltip title='This change will affect all users' arrow>
            <Button
              variant='contained'
              onClick={error || applyButtonClicked ? null : handlePostValue}
              color='success'
              sx={{
                backgroundColor: variables.successColor,
                marginTop: '5px',
                opacity: `${error || applyButtonClicked ? 0.5 : 1} !important`,
                pointerEvents: error || applyButtonClicked ? 'none' : 'auto'
              }}
              fullWidth
              disableElevation
            >
              <Typography
                variant='body2'
                fontFamily={variables.textFontFamily}
                fontWeight={600}
                textTransform='none'
                lineHeight={1}
              >
                {applyButtonClicked ? 'Applying...' : 'Apply'}
              </Typography>
            </Button>
          </Tooltip>
        </Grow>
      )}
    </Box>
  )
}
