import React from 'react'

import { Box, Typography, Button, Divider } from '@mui/material'

import { Utils } from '../../../helpers/Utils'
import { appTimezone } from '../../../types/global'

import variables from '../../../variables.module.scss'

import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded'

interface ResetToAppTimezoneSectionProps {
  show: boolean
  handleReset: () => void
}

export const ResetToAppTimezoneSection: React.FC<ResetToAppTimezoneSectionProps> = ({ show, handleReset }) => {
  if (!show) {
    return null
  }

  return (
    <Box width='100%'>
      <Typography
        variant='body1'
        fontFamily={variables.textFontFamily}
        color={variables.fontColor}
        marginBottom='10px'
      >
        You can go back & reset to the app-level timezone: <br />
        <span
          style={{
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 500,
            fontSize: '14px',
          }}
        >
          (UTC{Utils.TimezoneMoment(appTimezone()).format('Z')}) {appTimezone()}
        </span>
      </Typography>
      <Button
        color='primary'
        variant='outlined'
        size='small'
        startIcon={<RestartAltRoundedIcon sx={{ marginRight: '5px' }} />}
        sx={{
          pl: '15px',
          pr: '15px'
        }}
        onClick={handleReset}
      >
        <Typography
          variant='body1'
          fontFamily={variables.textFontFamily}
          fontWeight={600}
          color={variables.textFontFamily}
        >
          Reset
        </Typography>
      </Button>
      <Divider
        sx={{
          width: '100%',
          marginTop: '20px',
          backgroundColor: variables.dataBackgroundColor
        }}
      />
    </Box>
  )
}
